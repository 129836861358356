import { ErrorHandler, NgModule, Injector } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { AuthService, BaseApiUrlInterceptor, ClaimsService } from '@mt-ng2/auth-module';
import { EnvironmentModule, EnvironmentModuleConfigToken } from '@mt-ng2/environment-module';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BreckErrorHandler, ErrorsModuleConfigToken } from '@mt-ng2/errors-module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './common/shared.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { ErrorConfigOverride } from './common/configs/error.config';
import { TimeZoneInterceptor } from '../app/time-zone.interceptor';
import { FacilityPortalLoginComponent } from './landing-page/facility-portal-login.component';
import { setGlobalInjector } from './global-injector';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { FacilityPortalFooterComponent } from './footer/facility-portal-footer.component';
import { FacilityPortalHeaderComponent } from './header/facility-portal-header.component';
import { FacilityLoginService } from './landing-page/login.service';
import { ComponentErrorHandlerModule } from '@mt-ng2/component-error-handler';
import { FacilityPortalConfirmationComponent } from './confirmation-page/facility-portal-confirmation.component';
import { AppRoutingComponent } from './app-routing.component';
import { UploadFacilityDocumentsComponent } from './upload-documents/upload-documents.component';
import { AttachmentTypeService } from './attachments/attachment-type.service';
import { BaseAttachmentsService } from './attachments/base-attachments.service';
import { DocumentDownloadPopupComponent } from './document-download-popup/facility-portal-download-document.component';
import { CookieModule } from '@mt-ng2/cookie';
import { MtDisableDuringHttpCallsModule } from '@mt-ng2/disable-during-http-calls';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        FacilityPortalLoginComponent,
        FacilityPortalFooterComponent,
        FacilityPortalHeaderComponent,
        FacilityPortalConfirmationComponent,
        AppRoutingComponent,
        UploadFacilityDocumentsComponent,
        DocumentDownloadPopupComponent,
    ],
    providers: [
        AuthService,
        FacilityLoginService,
        ClaimsService,
        AttachmentTypeService,
        BaseAttachmentsService,
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInterceptor, multi: true, deps: [HttpBackend] },
        { provide: ErrorsModuleConfigToken, useValue: ErrorConfigOverride },
        { provide: HTTP_INTERCEPTORS, useClass: BaseApiUrlInterceptor, multi: true },
        { provide: EnvironmentModuleConfigToken, useValue: environment },
    ],
    imports: [
        BrowserModule,
        NgbModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.withOptions({ secure: true, sameSite: 'strict' }),
        EnvironmentModule,
        AppRoutingModule,
        NotificationsModule,
        ComponentErrorHandlerModule,
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
        MtDisableDuringHttpCallsModule.forRoot(),
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
