import { Component, OnInit } from "@angular/core";
import { AppComponent } from "../app.component";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-auto-ime-service-detail',
    templateUrl: './upload-documents.component.html',
})
export class UploadFacilityDocumentsComponent implements OnInit {

    private _headerAndFooterVisible = false;
    serviceId = 0;

    constructor(
        private appComponent: AppComponent,
        private route: ActivatedRoute,
        ){}

    ngOnInit(): void {
        this.hideHeaderAndFooter();
        this.serviceId = +this.route.snapshot.paramMap.get('serviceId');
    }

    hideHeaderAndFooter() {
        this.appComponent.toggleHeaderAndFooter( this._headerAndFooterVisible);
    }

    ngOnDestroy(): void {
        this.appComponent.toggleHeaderAndFooter(this._headerAndFooterVisible = true);
    }
}

