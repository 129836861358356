// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .cdk-drop-list-dragging .cdk-drag {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
            .cdk-drag-animating {
                transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
            }
            td {
                border: 1px solid #ccc;
            }
        `, "",{"version":3,"sources":["webpack://./src/facility-portal/attachments/attachments.component.ts"],"names":[],"mappings":";YACY;gBACI,sDAAsD;YAC1D;YACA;gBACI,sDAAsD;YAC1D;YACA;gBACI,sBAAsB;YAC1B","sourcesContent":["\n            .cdk-drop-list-dragging .cdk-drag {\n                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n            }\n            .cdk-drag-animating {\n                transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);\n            }\n            td {\n                border: 1px solid #ccc;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
