import { Component, OnInit } from '@angular/core';
import { appPaths } from './default-routes/app-paths.library';
import { Router } from '@angular/router';

@Component({
    template: '',
})
export class AppRoutingComponent implements OnInit {
    constructor( private router: Router) { }

    ngOnInit(): void {
      void this.router.navigate(['/', appPaths.login]);
    }
}
