import { Component} from '@angular/core';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-facility-portal-header',
    template: `
                <div class="text-center">
                   <img src="{{ bannerLogo }}" alt="BannerWithTagline_Logo" style="width:400px" /><span>&nbsp;</span><br /><br />
               </div>
    `,
})
export class FacilityPortalHeaderComponent  {
    bannerLogo = `${environment.imgPath}banner_logo_tagline.png`;
    constructor() {}
}
