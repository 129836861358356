import { Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FileItem, FileLikeObject } from 'ng2-file-upload';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AttachmentTypeService } from './attachment-type.service';
import { IAttachmentType } from '@model/interfaces/attachment-type';
import {
    BaseAttachmentsService,
    DocumentCardSortPropertyEnums,
    IDocumentSortParams,
    DocumentCardSortDirectionEnums,
} from './base-attachments.service';
import { DocumentTransactionTypeEnums } from '../common/constants/Enums';
import { DocumentDynamicControlsPartial } from '@model/partials/document-partial.form-controls';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DocumentTransactionLogService } from './document-transaction-log.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AttachmentsControlComponent } from '../attachment-control/attachment-control.component';
import { AttachmentTypeEnums } from '../../app/common/constants/Enums';
import { CommonAttachmentsService } from '@app-shared/services/common-attachments.service';

@Component({
    selector: 'app-common-attachments',
    styles: [
        `
            .cdk-drop-list-dragging .cdk-drag {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
            .cdk-drag-animating {
                transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
            }
            td {
                border: 1px solid #ccc;
            }
        `,
    ],
    templateUrl: './attachments.component.html',
})
export class AttachmentsComponent implements OnInit {
    @Input('entityId') entityId: number;
    @Input('type') type: string;
    @Output('onDocumentChanged') onDocumentChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output('onLoadMore') onLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('docComponent') docComponent: AttachmentsControlComponent;

    errorMessage: string;

    attachmentTypes: IAttachmentType[] = [];
    documentForm: UntypedFormGroup;
    abstractDocumentControls: any;
    formCreated = false;
    subscriptions = new Subscription();
    selectedAttachmentType: number;
    noOfPagesPerDoc: number[] = [];
    doubleClickIsDisabled = false;
    documentUploadQueue: FileItem[] = [];
    sortPropertyEnums = DocumentCardSortPropertyEnums;
    currentSort: IDocumentSortParams;;

    $documentsSubscription: Subscription;
    name = '';
    loaded = 0;
    triggerActionOnService: boolean = true;
    successfullyUploadedDocCount = 0;
    uploadedDocumentsNameList: string[] = [];
    isValidBillDocExtension: boolean = false;
    isPasswordProtected: boolean = false;

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private attachmentTypesService: AttachmentTypeService,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
        private router: Router,
        private docTransactionLogService: DocumentTransactionLogService,
        private commonAttachmentsService: CommonAttachmentsService
    ) {}

    ngOnInit(): void {
        this.getAttachmentTypesAndBuildForm();
    }

    setFailureFunctionOnDocComponent(): void {
        if (this.docComponent) {
            this.docComponent.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.$documentsSubscription?.unsubscribe();
    }

    getAttachmentTypesAndBuildForm(): void {
        this.attachmentTypesService.getAttachmentTypes().subscribe((types) => {
                this.attachmentTypes = types;
                this.buildForm();
        });
    }

    buildForm(): void {
        this.abstractDocumentControls = new DocumentDynamicControlsPartial(null, {
                attachmentTypes: this.attachmentTypes,
         }).Form;

        this.documentForm = this.fb.group({
            Document: this.fb.group({}),
        });
        this.setFailureFunctionOnDocComponent();
        this.formCreated = true;
    }

    onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): void {
        const allowedTypes = this.docComponent.allowedMimeType.join();
        switch (filter.name) {
            case 'fileSize':
                this.errorMessage = `Maximum upload size exceeded (${item.size} of ${this.docComponent.maxFileSize} allowed)`;
                break;
            case 'mimeType':
                this.errorMessage = `Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`;
                break;
            default:
                this.errorMessage = `Unknown error (filter is ${filter.name})`;
        }
        this.notificationsService.error(this.errorMessage);
    }

    save(): void {

       if (this.selectedAttachmentType === +AttachmentTypeEnums.Bill_Physician_Expert) {
             this.isValidBillDocExtension = this.commonAttachmentsService.IsValidDocumentExtension(this.docComponent.uploader.queue);
             if (!this.isValidBillDocExtension)
                  return;
       }

        this.documentUploadQueue = this.docComponent.uploader.queue;
            if (this.documentUploadQueue && this.documentUploadQueue.length) {
                void this.tryUploadDocuments();
            } else {
                setTimeout(() => (this.doubleClickIsDisabled = false));
                this.notificationsService.error('No Files Chosen');
            }
    }

    // Async upload function that sets file upload status upon successful or unsuccessful upload
    async upload(file: FileItem,triggerActionOnService: boolean): Promise<any> {
        file.isUploading = true;
        file.isError = false;
        const index = this.documentUploadQueue.findIndex((f) => f._file.name === file._file.name);
        const numPages = this.noOfPagesPerDoc[index] ? this.noOfPagesPerDoc[index] : 0;
        await this.attachmentsService.createAndUpload(this.type, this.entityId, file._file, this.selectedAttachmentType, 0, numPages,triggerActionOnService).toPromise();
    }

    async tryUploadDocuments(): Promise<void> {
        this.successfullyUploadedDocCount = 0;

        for (const file of this.documentUploadQueue) {
            if (!file.isUploaded) {
                try {
                    await this.upload(file,this.triggerActionOnService).then(() => {
                        this.setDocumentUploadStatus(file, true);
                        this.setUploadedDocumentNameList(file.file.name);
                        this.notificationsService.success("Successfully Uploaded");
                        this.successfullyUploadedDocCount++;
                        this.triggerActionOnService = false;
                    });
                } catch (err) {
                    if (err.error === 'Password Protected.') {
                        this.isPasswordProtected = true;
                    }

                    this.setDocumentUploadStatus(file, false);
                    this.docTransactionLogService.logError(err as HttpErrorResponse, null, DocumentTransactionTypeEnums.MANUAL_UPLOAD_FE).subscribe();
                }
            }
        }
        this.triggerActionOnService = true;
        this.naviageToDownloadDocumentPage(this.successfullyUploadedDocCount);
        this.completeDocumentsSave(this.selectedAttachmentType);
    }

    completeDocumentsSave(attachmentType: number): void {
        setTimeout(() => (this.doubleClickIsDisabled = false), 200);
        if (this.queueHasErrors) {

            if (this.isPasswordProtected) {
                this.notificationsService.error(
                    `One or more documents have been uploaded with protection. Please re-upload the document without protection.`,
                );
                this.isPasswordProtected = false;
            } else {
                this.notificationsService.error(`The following files marked with a red 'X' failed to upload, click 'Try Again' to retry the upload.`);
            }

        } else {
            this.notifyAndResetDocumentUpload(attachmentType);
        }
    }

    notifyAndResetDocumentUpload(attachmentType: number): void {
        this.onDocumentChanged.emit(attachmentType);
        this.clearDocumentUploadQueue();
    }

    // Set queued document's upload status
    setDocumentUploadStatus(doc: FileItem, uploaded: boolean): void {
        doc.isUploading = false;
        doc.isUploaded = uploaded;
        doc.isError = !uploaded;
    }

    setUploadedDocumentNameList(docName:string): void{
        this.uploadedDocumentsNameList.push(docName);
    }

    get queueHasErrors(): boolean {
        return this.documentUploadQueue.some((doc) => doc.isError);
    }

    cancel(): void {
        this.clearDocumentUploadQueue();
    }

    clearDocumentUploadQueue(): void {
        this.docComponent.uploader.queue = [];
        this.documentUploadQueue = [];
        this.documentForm.get('Document.AttachmentTypeId').reset();
    }

    attachmentTypeChanges(value: number): void {
        this.selectedAttachmentType = value;
    }

    sort(sortProperty: DocumentCardSortPropertyEnums): void {
        const direction = this.currentSort.property === sortProperty ? 1 - this.currentSort.direction : DocumentCardSortDirectionEnums.Asc;
        this.attachmentsService.sortProperty.next({ property: sortProperty, direction: direction });
    }

    naviageToDownloadDocumentPage(successfullyUploadedCount: number): void{
        if(successfullyUploadedCount > 0){
          this.attachmentsService.setUploadedDocuments(this.uploadedDocumentsNameList)
          void this.router.navigate([`download-documents/${this.entityId}`]);
        }
    }
}
