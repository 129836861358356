import { Component, OnInit } from '@angular/core';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { FacilityLoginService } from '../landing-page/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { IIndividual } from '@model/interfaces/individual';
import { UserInactivityMonitorService } from '@app-shared/services/user-inactivity-monitor.service';
import { PortalTypeEnums } from '@app-shared/Enums/PortalTypeEnum';

@Component({
    selector: 'app-facility-portal-confirmation',
    styleUrls: ['./facility-portal-confirmation.component.less'],
    templateUrl: './facility-portal-confirmation.component.html',
})
export class FacilityPortalConfirmationComponent implements OnInit {

    serviceDetail:IImedClaimService;
    claimantName: string = "";
    physicianFacilityIndividualName: string = "";

    constructor(
        private pageTitlesService: PageTitlesService,
        private loginService: FacilityLoginService,
        private router: Router,
        private route: ActivatedRoute,
        private userInactivitymonitorService: UserInactivityMonitorService,
    ) {}

    ngOnInit(): void {
        const serviceId = +this.route.snapshot.paramMap.get('serviceId');

        this.loginService.getServiceDetail(serviceId).subscribe((serviceDetail) => {
            this.serviceDetail=serviceDetail;

            this.claimantName= serviceDetail.ImedClaim.FirstName +" "+ serviceDetail.ImedClaim.LastName;
            if(serviceDetail.PhysicianId){
                this.physicianFacilityIndividualName= this.GetName(serviceDetail.Physician.Individual as IIndividual);
            } else{
                this.physicianFacilityIndividualName = serviceDetail.Individual ? this.GetName(serviceDetail.Individual as IIndividual) : serviceDetail.AddressBook?.FacilityName;
            }
        });
        this.setPageTitle();
        this.userInactivitymonitorService.init(30,PortalTypeEnums.Facility);
    }

    navigateToUploadDocument(): void{
        void this.router.navigate(['upload-documents', this.serviceDetail.Id]);
    }

    navigateToLoginPage(): void{
        void this.router.navigate(['login']);
    }

    GetName(individual: IIndividual): string{
        return `${individual.FirstName} ${individual.LastName}`;
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Confirmation Page');
    }
}
