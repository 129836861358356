import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appPaths } from './default-routes/app-paths.library';
import { FacilityPortalLoginComponent } from './landing-page/facility-portal-login.component';
import { FacilityPortalConfirmationComponent } from './confirmation-page/facility-portal-confirmation.component';
import { AppRoutingComponent } from './app-routing.component';
import { UploadFacilityDocumentsComponent } from './upload-documents/upload-documents.component';
import { DocumentDownloadPopupComponent } from './document-download-popup/facility-portal-download-document.component';

const imedServiceEntityConfig = {
    entityIdParam: 'serviceId',
};

const appRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: appPaths.login, component: FacilityPortalLoginComponent },
    {
        component: FacilityPortalConfirmationComponent,
        path: `${appPaths.confirm}/:${imedServiceEntityConfig.entityIdParam}`,
    },
    {
        component: UploadFacilityDocumentsComponent,
        path: `${appPaths.facilityDocumentUpload}/:${imedServiceEntityConfig.entityIdParam}`,
        pathMatch: 'full',
    },
    {
        component: DocumentDownloadPopupComponent,
        path: `${appPaths.facilityDocumentDownload}/:${imedServiceEntityConfig.entityIdParam}`,
        pathMatch: 'full',
    },
    {
        component: AppRoutingComponent,
        path: '**',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
