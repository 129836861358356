import { Injectable } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem } from 'ng2-file-upload';

@Injectable({
    providedIn: 'root',
})
export class CommonAttachmentsService {
    constructor(private notificationsService: NotificationsService) {}

    IsValidDocumentExtension(documents: FileItem[]): boolean {
        for (const doc of documents) {
            if (!doc.file.type.includes('pdf') && !doc.file.type.includes('PDF')) {
                this.notificationsService.error('Only PDF files are allowed for bills');
                return false;
            }
        }
        return true;
    }
}
