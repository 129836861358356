// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-first-letter {
  font-size: larger;
  font-weight: bold;
}
.custom-box-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}
.bold-font {
  font-weight: bold;
}
.upload-btn-border {
  border-width: 1px;
  border-color: green;
}
.download-btn-border {
  border-width: 1px;
  border-color: brown;
}
.miles-card {
  color: #0d5e0d;
  font-size: large;
  background: #d4f7d4;
  border: 2px solid green;
}
`, "",{"version":3,"sources":["webpack://./src/facility-portal/document-download-popup/facility-portal-download-document.component.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;AACA;EACG,wCAAA;AACH;AACA;EACI,iBAAA;AACJ;AACA;EACG,iBAAA;EACA,mBAAA;AACH;AACA;EACG,iBAAA;EACA,mBAAA;AACH;AACA;EACI,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AACJ","sourcesContent":[".custom-first-letter{\n    font-size: larger;\n    font-weight: bold;\n}\n.custom-box-shadow {\n   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);\n}\n.bold-font{\n    font-weight: bold;\n}\n.upload-btn-border{\n   border-width: 1px;\n   border-color: green;\n}\n.download-btn-border{\n   border-width: 1px;\n   border-color: brown;\n}\n.miles-card{\n    color: rgb(13, 94, 13);\n    font-size: large;\n    background: rgb(212, 247, 212);\n    border:2px solid green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
