import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
@Injectable()
export class FacilityLoginService {
    constructor(public http: HttpClient, private environmentService: EnvironmentService) {
    }

    serviceDetail: IImedClaimService;
    baseUrl = `${this.environmentService.config.baseApiUrl}/api/v${this.environmentService.config.apiVersion}`;

    isValidFacilityLogin(serviceOrderNumber: number, lastName: string): Observable<any> {
        const fullUrl = `${this.baseUrl}/facility/facilityAccess/${serviceOrderNumber}`;
        const params = new HttpParams().set('lastName', lastName);
        return this.http.get<any>(fullUrl, { params });
    }

    getServiceDetail(serviceOrderNumber: number): Observable<any> {
        const fullUrl = `${this.baseUrl}/facility/service/${serviceOrderNumber}`;
        return this.http.get<any>(fullUrl);
    }
}
