import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'attachment-control',
    styles: ['.success { color: #8AC175; }'],
    templateUrl: './attachment-control.component.html',
})
export class AttachmentsControlComponent {
    private _showAllowedExtension = true;
    @Input('showAllowedExtension')
    get showAllowedExtension(): boolean {
        return this._showAllowedExtension;
    }
    set showAllowedExtension(value: boolean) {
        this._showAllowedExtension = value;
    }

    allowedMimeType: string[] = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    maxFileSize = 175 * 1024 * 1024;
    isHTML5 = true;
    uploader: FileUploader;
    headerTitle = 'Upload Document';

    private _showUploadOnly = false;
    @Input('showNoPageFields') showNoPageFields;
    @Input('noOfPagesPerDoc') noOfPagesPerDoc;
    @Input('singleFileOnly') singleFileOnly;

    @Input('showUploadOnly')
    get showUploadOnly(): boolean {
        return this._showUploadOnly;
    }
    set showUploadOnly(value: boolean) {
        this._showUploadOnly = value;
    }
    constructor(private cdr: ChangeDetectorRef) {
        this.uploader = new FileUploader({
            url: '',
            allowedMimeType: this.allowedMimeType,
            isHTML5: this.isHTML5,
            maxFileSize: this.maxFileSize,
        });
    }
}
