import { IFilmInformation } from '@model/interfaces/film-information.d';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';

export const emptyFilmInformation: IFilmInformation = {
    Archived: false,
    BodyPartImagedId: null,
    CreatedById: 0,
    DateCreated: new Date(),
    DateProcessed: null,
    DeleteRequested: false,
    FacilityName: null,
    Id: 0,
    ImageDate: null,
    ImageTypeId: null,
    ImedClaimServiceId: 0,
    StudyUuid: null,
};

@Injectable()
export class FilmInformationService extends BaseService<IFilmInformation> {
    constructor(public http: HttpClient) {
        super('/filmInformations', http);
    }

    getEmptyFilmInformation(): IFilmInformation {
        return { ...emptyFilmInformation };
    }

    getByImedClaimServiceId(imedClaimServiceId: number, serviceTypeId: number): Observable<IFilmInformationDTO[]> {
        return this.http.get<IFilmInformationDTO[]>(`/filmInformations/getByImedClaimServiceId/${imedClaimServiceId}/${serviceTypeId}`);
    }

    getByImedClaimId(imedClaimId: number): Observable<IFilmInformationDTO[]> {
        return this.http.get<IFilmInformationDTO[]>(`/filmInformations/get-by-imed-claim-id/${imedClaimId}`);
    }

    selectFilm(filmId: number, imedClaimServiceId: number): Observable<number> {
        return this.http.post<number>(`/filmInformations/select-film/${imedClaimServiceId}/${filmId}`, {});
    }

    unSelectFilm(selectedFilmId: number): Observable<any> {
        return this.http.post<any>(`/filmInformations/unselect-film/${selectedFilmId}`, {});
    }

    selectAllFilms(imedClaimServiceId: number): Observable<any> {
        return this.http.post<number>(`/filmInformations/select-all-films/${imedClaimServiceId}`, {});
    }

    unSelectAllFilms(imedClaimServiceId: number): Observable<any> {
        return this.http.post<any>(`/filmInformations/unselect-all-films/${imedClaimServiceId}`, {});
    }

    normalizeFilmInformation(filmInformation: IFilmInformation): IFilmInformation {
        const normalizedfilmInformation = this.getEmptyFilmInformation();
        normalizedfilmInformation.Id = filmInformation.Id;
        normalizedfilmInformation.Archived = false;
        normalizedfilmInformation.BodyPartImagedId = filmInformation.BodyPartImagedId;
        normalizedfilmInformation.CreatedById = filmInformation.CreatedById;
        normalizedfilmInformation.DateCreated = filmInformation.DateCreated;
        normalizedfilmInformation.FacilityName = filmInformation.FacilityName;
        normalizedfilmInformation.DateProcessed = filmInformation.DateProcessed;
        normalizedfilmInformation.ImageDate = filmInformation.ImageDate;
        normalizedfilmInformation.ImageTypeId = filmInformation.ImageTypeId;
        normalizedfilmInformation.ImedClaimServiceId = filmInformation.ImedClaimServiceId;
        normalizedfilmInformation.StudyUuid = filmInformation.StudyUuid;
        return normalizedfilmInformation;
    }

    requestDelete(filmInformationId: number): Observable<any> {
        return this.http.put<any>(`/filmInformations/request-delete`, filmInformationId);
    }

    deleteFilm(filmInformationId: number): Observable<any> {
        return this.http.delete<any>(`/filmInformations/delete-film/${filmInformationId}`);
    }

    deleteAmbraStudy(filmInformationId: number): Observable<any> {
        return this.http.delete<any>(`/filmInformations/delete-ambra-study/${filmInformationId}`);
    }

    getSession(): Observable<any> {
        return this.http.get<any>(`/filmInformations/get-session`);
    }
}
