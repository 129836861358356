import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IDocumentTransactionLog } from '@model/interfaces/document-transaction-log';
import { Observable } from 'rxjs';

export const emptyDocumentTransactionLog: IDocumentTransactionLog = {
    Id: 0,
    StartTime: null,
    TransactionTypeId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class DocumentTransactionLogService extends BaseService<IDocumentTransactionLog> {
    constructor(public http: HttpClient) {
        super('/facility-document-transaction-logs', http);
    }

    getEmptyDocumentTransactionLog(): IDocumentTransactionLog {
        return { ...emptyDocumentTransactionLog };
    }

    logError(err: HttpErrorResponse, id: number, transactionType: number): Observable<any> {
        const log = this.getEmptyDocumentTransactionLog();
        log.DocumentId = id;
        log.Error = err.message;
        log.StartTime = new Date();
        log.TransactionTypeId = transactionType;
        return this.create(log);
    }
}
