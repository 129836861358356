// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
           .custom-first-letter{
                font-size: larger;
                font-weight: bold;
            }
            .custom-box-shadow {
               box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
            }
            .custom-ridge-border {
               border-style: ridge !important;
               border-width: 4px;
               border-color: #AFE1AF;
            }
        `, "",{"version":3,"sources":["webpack://./src/facility-portal/landing-page/facility-portal-login.component.ts"],"names":[],"mappings":";WACW;gBACK,iBAAiB;gBACjB,iBAAiB;YACrB;YACA;eACG,wCAAwC;YAC3C;YACA;eACG,8BAA8B;eAC9B,iBAAiB;eACjB,qBAAqB;YACxB","sourcesContent":["\n           .custom-first-letter{\n                font-size: larger;\n                font-weight: bold;\n            }\n            .custom-box-shadow {\n               box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);\n            }\n            .custom-ridge-border {\n               border-style: ridge !important;\n               border-width: 4px;\n               border-color: #AFE1AF;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
