import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDocument } from '../interfaces/document';
import { IAttachmentType } from '../interfaces/attachment-type';
import { IAutomatedTemplate } from '../interfaces/automated-template';
import { IDocumentUploadSource } from '../interfaces/document-upload-source';

export interface IDocumentDynamicControlsParameters {
    formGroup?: string;
    attachmentTypes?: IAttachmentType[];
    uploadSources?: IDocumentUploadSource[];
    templates?: IAutomatedTemplate[];
}

export class DocumentDynamicControls {

    formGroup: string;
    attachmentTypes: IAttachmentType[];
    uploadSources: IDocumentUploadSource[];
    templates: IAutomatedTemplate[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private document?: IDocument, additionalParameters?: IDocumentDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Document';
        this.attachmentTypes = additionalParameters && additionalParameters.attachmentTypes || undefined;
        this.uploadSources = additionalParameters && additionalParameters.uploadSources || undefined;
        this.templates = additionalParameters && additionalParameters.templates || undefined;

        this.Form = {
            AttachmentTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attachment Type',
                name: 'AttachmentTypeId',
                options: this.attachmentTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.document && this.document.AttachmentTypeId || null,
            }),
            DateUpload: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Upload',
                name: 'DateUpload',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.document && this.document.DateUpload || null,
            }),
            FilePath: new DynamicField({
                formGroup: this.formGroup,
                label: 'File Path',
                name: 'FilePath',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.document && this.document.hasOwnProperty('FilePath') && this.document.FilePath !== null ? this.document.FilePath.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(500) ],
                validators: { 'required': true, 'maxlength': 500 },
                value: this.document && this.document.hasOwnProperty('Name') && this.document.Name !== null ? this.document.Name.toString() : '',
            }),
            NoOfPages: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pages',
                name: 'NoOfPages',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.document && this.document.NoOfPages || null,
            }),
            TemplateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Template',
                name: 'TemplateId',
                options: this.templates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.document && this.document.TemplateId || null,
            }),
            UploadedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Uploaded By',
                name: 'UploadedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.document && this.document.UploadedBy || null,
            }),
            UploadSourceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Upload Source',
                name: 'UploadSourceId',
                options: this.uploadSources,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.document && this.document.UploadSourceId || 1,
            }),
        };

        this.View = {
            AttachmentTypeId: new DynamicLabel({
			    label: 'Attachment Type',
			    value: getMetaItemValue(this.attachmentTypes as unknown as IMetaItem[], this.document && this.document.hasOwnProperty('AttachmentTypeId') && this.document.AttachmentTypeId !== null ? this.document.AttachmentTypeId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateUpload: new DynamicLabel({
			    label: 'Date Upload',
			    value: this.document && this.document.DateUpload || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FilePath: new DynamicLabel({
			    label: 'File Path',
			    value: this.document && this.document.hasOwnProperty('FilePath') && this.document.FilePath !== null ? this.document.FilePath.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.document && this.document.hasOwnProperty('Name') && this.document.Name !== null ? this.document.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            NoOfPages: new DynamicLabel({
			    label: 'No Of Pages',
			    value: this.document && this.document.NoOfPages || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            TemplateId: new DynamicLabel({
			    label: 'Template',
			    value: getMetaItemValue(this.templates as unknown as IMetaItem[], this.document && this.document.hasOwnProperty('TemplateId') && this.document.TemplateId !== null ? this.document.TemplateId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            UploadedBy: new DynamicLabel({
			    label: 'Uploaded By',
			    value: this.document && this.document.UploadedBy || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
            UploadSourceId: new DynamicLabel({
			    label: 'Upload Source',
			    value: getMetaItemValue(this.uploadSources as unknown as IMetaItem[], this.document && this.document.hasOwnProperty('UploadSourceId') && this.document.UploadSourceId !== null ? this.document.UploadSourceId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
