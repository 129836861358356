// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            .container {
                margin: 0 auto;
                width: 200px;
                height: 200px;
                align-items: center;
                justify-content: center;
                display: flex;
                border: 5px ;
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            }
        `, "",{"version":3,"sources":["webpack://./src/facility-portal/footer/facility-portal-footer.component.ts"],"names":[],"mappings":";YACY;gBACI,cAAc;gBACd,YAAY;gBACZ,aAAa;gBACb,mBAAmB;gBACnB,uBAAuB;gBACvB,aAAa;gBACb,YAAY;gBACZ,kBAAkB;gBAClB,gBAAgB;gBAChB,uCAAuC;YAC3C","sourcesContent":["\n            .container {\n                margin: 0 auto;\n                width: 200px;\n                height: 200px;\n                align-items: center;\n                justify-content: center;\n                display: flex;\n                border: 5px ;\n                border-radius: 50%;\n                overflow: hidden;\n                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
