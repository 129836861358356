import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';

export enum DocumentCardSortPropertyEnums {
    AttachmentType = 1,
    Filename,
    Service,
    UploadedBy,
    DateUploaded,
    Source,
    Pages,
}

export enum DocumentCardSortDirectionEnums {
    Asc,
    Desc,
}

export interface IDocumentSortParams {
    property: DocumentCardSortPropertyEnums;
    direction: DocumentCardSortDirectionEnums;
}

@Injectable()
export class BaseAttachmentsService extends BaseService<IAttachmentDTO> {

    private _defaultSort: IDocumentSortParams = {
        direction: DocumentCardSortDirectionEnums.Asc,
        property: DocumentCardSortPropertyEnums.AttachmentType,
    };
    public sortProperty: BehaviorSubject<IDocumentSortParams> = new BehaviorSubject<IDocumentSortParams>(this._defaultSort);
    public refreshRequested: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _uploadedDocuments: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    setUploadedDocuments(value: string[]): void {
        this._uploadedDocuments.next(value);
    }

    getUploadedDocuments(): Observable<string[]> {
        return this._uploadedDocuments.asObservable();
    }

    constructor(public http: HttpClient) {
        super('/', http);
    }

    currentSort(): Observable<IDocumentSortParams> {
        return this.sortProperty.asObservable();
    }

    requestRefresh(): void {
        this.refreshRequested.next(true);
    }

    createAndUpload(type: string, entityId: number, file: File, attachmentTypeId: number, templateId: number, noofPages = 0, triggerActionOnService:boolean): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`/facility-documents/upload/${entityId}/${attachmentTypeId}/${noofPages}/${templateId}/${triggerActionOnService}`, formData);
    }

    downloadTemplate(templateId: number, uploadedDocumentsNameList: any): Observable<any> {
        return this.http.post(`/facility-documents/download/${templateId}`,uploadedDocumentsNameList, {
            observe: 'response',
            responseType: 'blob',
        });
    }
}
