import { Component} from '@angular/core';
import { EnvironmentService } from '@mt-ng2/environment-module';

@Component({
    selector: 'app-facility-portal-footer',
    styles: [
        `
            .container {
                margin: 0 auto;
                width: 200px;
                height: 200px;
                align-items: center;
                justify-content: center;
                display: flex;
                border: 5px ;
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            }
        `,
    ],
    template: `
                <div class="container">
                      <img src="{{ logoFull }}" alt="Logo" style="width: 90%; height: 90%; object-fit: contain;">
               </div>
    `,
})
export class FacilityPortalFooterComponent  {

    logoFull = `${this.environmentService.config.imgPath}logo-full.png`;

    constructor(
        private environmentService: EnvironmentService,
    ) {}

}
