import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
@Component({
    selector: 'app-facility-portal-download-document',
    styleUrls: ['./facility-portal-download-document.component.less'],
    templateUrl: './facility-portal-download-document.component.html',
})
export class DocumentDownloadPopupComponent implements OnInit {
    serviceId: number;
    uploadedDocumentsNameList: string[]=[];
    uploadedDocumentsSubscription: Subscription;

    constructor(
        private attachmentsService: BaseAttachmentsService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.serviceId = +this.route.snapshot.paramMap.get('serviceId');
        this.uploadedDocumentsSubscription = this.attachmentsService.getUploadedDocuments().subscribe((data) => {
            this.uploadedDocumentsNameList = data;
        });
    }

    navigateToLoginPage(): void{
        void this.router.navigate(['login']);
    }

    navigateToUploadDocPage(): void{
        void this.router.navigate([`upload-documents/${this.serviceId}`]);
    }

    downloadUploadedDocumentsReceipt(): void{
        this.attachmentsService.downloadTemplate(this.serviceId,this.uploadedDocumentsNameList).subscribe(
            (response) => {
                 const blob = new Blob([response.body as BlobPart], { type: 'application/pdf' });
                 saveAs(blob, "Receipt_Of_Uploaded_Documents");
            },
            () => {
                 this.notificationsService.error('Document Download failed.');
            },
        );
    }

    ngOnDestroy(): void {
        if (this.uploadedDocumentsSubscription) {
            this.uploadedDocumentsSubscription.unsubscribe();
        }
        this.cdr.detach();
    }
}
