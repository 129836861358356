// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-first-letter {
  font-size: larger;
  font-weight: bold;
}
.custom-box-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}
.bold-font {
  font-weight: bold;
}
.correct-btn-border {
  border-width: 1px;
  border-color: green;
}
.incorrect-btn-border {
  border-width: 1px;
  border-color: brown;
}
`, "",{"version":3,"sources":["webpack://./src/facility-portal/confirmation-page/facility-portal-confirmation.component.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;AACJ;AACA;EACG,wCAAA;AACH;AACA;EACI,iBAAA;AACJ;AACA;EACG,iBAAA;EACA,mBAAA;AACH;AACA;EACG,iBAAA;EACA,mBAAA;AACH","sourcesContent":[".custom-first-letter{\n    font-size: larger;\n    font-weight: bold;\n}\n.custom-box-shadow {\n   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);\n}\n.bold-font{\n    font-weight: bold;\n}\n.correct-btn-border{\n   border-width: 1px;\n   border-color: green;\n}\n.incorrect-btn-border{\n   border-width: 1px;\n   border-color: brown;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
