import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PortalTypeEnums } from '@app-shared/Enums/PortalTypeEnum';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { UserInactivityService } from '@mt-ng2/user-inactivity-module';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserInactivityMonitorService implements OnDestroy {
    $currentUserSubscription: Subscription;
    $inactivitySubscription: Subscription;

    inactivityTime: number;
    portal: PortalTypeEnums ;

    constructor(private authService: AuthService, private userInactivityService: UserInactivityService,  private router: Router) {}

    init(inactivityTime: number, portal: PortalTypeEnums): void {
        this.inactivityTime = inactivityTime;
        this.portal = portal;

        this.authService.currentUser.subscribe((user: ILoggedIn) => {
            if (user && user.Id > 0 || portal === PortalTypeEnums.Facility) {
                this.createInactivitySubscription();
            } else {
                this.destroyInactivitySubscription();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyInactivitySubscription();
        if (this.$currentUserSubscription) {
            this.$currentUserSubscription.unsubscribe();
        }
    }

    destroyInactivitySubscription(): void {
        if (this.$inactivitySubscription) {
            this.$inactivitySubscription.unsubscribe();
        }
    }

    createInactivitySubscription(): void {
        this.destroyInactivitySubscription();
        this.$inactivitySubscription = this.userInactivityService.monitor(this.inactivityTime).subscribe(() => {

            if(this.portal === PortalTypeEnums.Facility){
                sessionStorage.removeItem('authToken');
                void this.router.navigate(['/login']);
            } else {
                this.authService.logout()
            }
        });
    }
}
