import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    template: `
        <div>
            <div class="wrapper" [style.min-height]="'100vh'">
                <br/>
                <app-facility-portal-header *ngIf="showHeaderAndFooter"></app-facility-portal-header>
                <ng-progress></ng-progress>
                <br/><br/>
                <div>
                    <span >
                        <router-outlet *ngIf="authenticated || loginComponent"></router-outlet>
                    </span>
                </div>
                <app-facility-portal-footer *ngIf="showHeaderAndFooter"></app-facility-portal-footer>
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit {
    title = 'app';
    showHeaderAndFooter: boolean = true;
    authenticated: boolean = false;
    loginComponent: boolean = false;
    routerSubscription: any;
    subscriptions: Subscription = new Subscription();

    constructor(private route: Router, private cdr: ChangeDetectorRef, ) {}

    ngOnInit(): void {
        const authToken = sessionStorage.getItem('authToken');
        if(authToken){
          this.isAuthenticated(true);
        }

        this.routerSubscription = this.route.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.checkLoginRoute();
              this.cdr.detectChanges();
            }
        });
    }

    checkLoginRoute() {
        const currentUrl = this.route.url;
        if (currentUrl.startsWith('/login')) {
             this.loginComponent = true;
        }
    }

    toggleHeaderAndFooter(show: boolean) {
        this.showHeaderAndFooter = show;
    }

    isAuthenticated(isAuthenticated: boolean) {
        this.authenticated = isAuthenticated;
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }
}
