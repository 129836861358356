import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { FacilityLoginService } from './login.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

interface LoginFormValues {
    serviceOrderNumber: number;
    lastName: string;
}

@Component({
    selector: 'app-facility-portal-login',
    styles: [
        `
           .custom-first-letter{
                font-size: larger;
                font-weight: bold;
            }
            .custom-box-shadow {
               box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
            }
            .custom-ridge-border {
               border-style: ridge !important;
               border-width: 4px;
               border-color: #AFE1AF;
            }
        `,
    ],
    templateUrl: './facility-portal-login.component.html',
})
export class FacilityPortalLoginComponent implements OnInit {
    loginForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private pageTitlesService: PageTitlesService,
        private loginService: FacilityLoginService,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,

    ) {}

    ngOnInit(): void {
        this.createForm();
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Login');
    }

    createForm(): void {
        if (!this.loginForm) {
            this.loginForm = this.fb.group({});
        }
        this.loginForm.addControl('lastName', new UntypedFormControl('', (c: AbstractControl) => Validators.required(c)));
        this.loginForm.addControl('serviceOrderNumber', new UntypedFormControl('', (c: AbstractControl) => Validators.required(c)));
    }

    showServiceOrderNumberRequiredError(): boolean {
        const control = this.loginForm.get('serviceOrderNumber');
        return control.touched && control.hasError('required');
    }

    showLastNameRequiredError(): boolean {
        const control = this.loginForm.get('lastName');
        return control.touched && control.hasError('required');
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value as LoginFormValues;
            this.loginService.isValidFacilityLogin(values.serviceOrderNumber, values.lastName).subscribe(
                (result) => {
                    if (result) {
                       this.notificationsService.success('Welcome');
                       sessionStorage.setItem('authToken', 'AuthToken');
                       void this.router.navigate(['confirm', values.serviceOrderNumber]);
                    }
                },
                (error:any) => {
                    this.error(error);
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }

    error(msg?: any): void {
        msg.error == "NameError" ?
                this.notificationsService.error('Incorrect Last Name.') :
                this.notificationsService.error('No Record Retrieval or Subpoena Processing Service found.Please Enter Valid Service Order Number.');
    }
}
