import { Observable } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TimeZoneInterceptor implements HttpInterceptor {
    constructor(private httpBackend: HttpBackend) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if request is for refreshing the navigation task list
        const date = new Date();
        const httpRequest = request.clone({
            setHeaders: {
                'Client-TimeZone-Offset': (-date.getTimezoneOffset()).toString(), // set to negative since c# uses an opposite convetion
            },
        });

        return next.handle(httpRequest);
    }
}
