export enum AttachmentTypeEnums {
    Cv = 1,
    Fee_Schedule = 3,
    Intro_Letter = 4,
    Registration_Licensing_Documents = 5,
    Facility_Specific_Record_Request_Form = 6,
    Other_Physician_Expert_Related_Document = 7,
    Invoice = 8,
    Letter_To_Physician = 9,
    Notice_Of_Appointment = 10,
    Retrieval_Documents_Complete = 11,
    Retrieval_Cover_Letter = 12,
    Notice_Of_Appointment_Cancelled = 13,
    Notice_Of_Appointment_Missed = 14,
    Film_Review_Cover_Letter = 15,
    Authorizations_All = 16,
    Legal_Foundation = 17,
    Bill_Physician_Expert = 18,
    Attendance_Records = 19,
    Records_Retrieved = 20,
    Medical_Records_Received = 21,
    Accounting_Document_Miscellaneous = 22,
    Addendum = 23,
    Report_From_Physician = 24,
    Power_Of_Attorney = 25,
    Appointment_Confirmation_To_Physician = 26,
    Completed_Retrieval_Cover = 27,
    Retrieval_LOR = 28,
    Authorization_Individual = 29,
    Re_Exam_Letter = 30,
    Radiology_Retrieval_Label = 31,
    Record_Review_Cover_Letter = 32,
    Welcome_Letter = 33,
    Archival_Document = 34,
    Correspondence = 35,
    Table_Of_Contents = 36,
    Compiled_Records = 37,
    Rejection = 38,
    Draft_Report = 39,
    Final_Report = 40,
    Report_Audit_To_Physician = 41,
    Addl_Records_Received = 42,
    Addl_Records_Retrieved = 43,
    Check_Copy = 44,
    EBT = 45,
    ID = 46,
    Intake_Form = 47,
    Misc = 48,
    Appointment_TBD_Letter = 50,
    Appointment_RescheduledTBD_Letter = 51,
    COVID_Appointment_Rescheduld_Letter = 52,
    COVID_Appointment_Rescheduled_TBD_Letter = 53,
    COVID_Appointment_TBD_Letter = 54,
    Completed_Rejection_Cover = 55,
    Introductory_Letter_Intro_To_Imes = 56,
    Expert_Credentialing_Form = 57,
    Notice_Of_Appointment_Rescheduled = 58,
    No_Records_On_File = 59,
    No_Records_On_File_Cover = 60,
    Undeliverable = 61,
    Undeliverable_Cover = 62,
    Additional_Records_Retrieved_Cover = 63,
    Proof_Of_Mailing = 64,
    Claimant_Provided_Records = 65,
    Automated_Templates = 66,
    Email_Compilation = 74,
    Photo = 75,
    Email = 76,
    Fee_To_Client = 77,
    Signature_Authorization = 78,
    Attorney_Signed_Deposition_Subpoena = 97,
    Executed_Deposition_Subpoena = 98,
    Executed_Subpoena_Duces_Tecum = 99,
    Attorney_Signed_Trial_Subpoena = 100,
    Executed_Trial_Subpoena = 101,
    Attorney_Signed_Other_Subpoena = 102,
    Executed_Other_Subpoena = 103,
    Attorney_Signed_Duces_Tecum_Subpoena = 104,
    Local_Deposition_Subpoena = 105,
    Foreign_Deposition_Subpoena = 106,
    Local_Attorney_Signed_Deposition_Subpoena = 107,
    Foreign_Attorney_Signed_Deposition_Subpoena = 108,
    Local_Executed_Deposition_Subpoena = 109,
    Foreign_Executed_Deposition_Subpoena = 110,
    Local_Subpoena_Duces_Tecum = 111,
    Foreign_Subpoena_Duces_Tecum = 112,
    Local_Attorney_Signed_Subpoena_Duces_Tecum = 113,
    Foreign_Attorney_Signed_Subpoena_Duces_Tecum = 114,
    Local_Executed_Subpoena_Duces_Tecum = 115,
    Foreign_Executed_Subpoena_Duces_Tecum = 116,
    Local_Trial_Subpoena = 117,
    Foreign_Trial_Subpoena = 118,
    Local_Attorney_Signed_Trial_Subpoena = 119,
    Foreign_Attorney_Signed_Trial_Subpoena = 120,
    Local_Executed_Trial_Subpoena = 121,
    Foreign_Executed_Trial_Subpoena = 122,
    Local_Other_Subpoena = 123,
    Foreign_Other_Subpoena = 124,
    Local_Attorney_Signed_Other_Subpoena = 125,
    Foreign_Attorney_Signed_Other_Subpoena = 126,
    Local_Executed_Other_Subpoena = 127,
    Foreign_Executed_Other_Subpoena = 128,
    All_Subpoenas = 129,
    Letter_of_Representation = 130,
    Executed_Letter_of_Representation = 131,
    Sample_Report = 132,
    Consent_to_Change = 133,
    Record_Order_Form = 134,
    Subpoena = 135,
    Bill = 145,
    Bill_Transcription = 139,
    Bill_Transportation = 140,
    Bill_Translation = 141,
    Bill_Radiology = 142,
    Bill_Surveillance_Background_Check = 143,
    Bill_Rental_Facility = 144,
    Transcription_Template = 147,
}

export enum SubpoenaPackageTypeEnums {
    Attorney_Signed_Deposition_Subpoena = 97,
    Executed_Deposition_Subpoena = 98,
    Executed_Subpoena_Duces_Tecum = 99,
    Attorney_Signed_Trial_Subpoena = 100,
    Executed_Trial_Subpoena = 101,
    Attorney_Signed_Other_Subpoena = 102,
    Executed_Other_Subpoena = 103,
    Attorney_Signed_Duces_Tecum_Subpoena = 104,
    Local_Deposition_Subpoena = 105,
    Foreign_Deposition_Subpoena = 106,
    Local_Attorney_Signed_Deposition_Subpoena = 107,
    Foreign_Attorney_Signed_Deposition_Subpoena = 108,
    Local_Executed_Deposition_Subpoena = 109,
    Foreign_Executed_Deposition_Subpoena = 110,
    Local_Subpoena_Duces_Tecum = 111,
    Foreign_Subpoena_Duces_Tecum = 112,
    Local_Attorney_Signed_Subpoena_Duces_Tecum = 113,
    Foreign_Attorney_Signed_Subpoena_Duces_Tecum = 114,
    Local_Executed_Subpoena_Duces_Tecum = 115,
    Foreign_Executed_Subpoena_Duces_Tecum = 116,
    Local_Trial_Subpoena = 117,
    Foreign_Trial_Subpoena = 118,
    Local_Attorney_Signed_Trial_Subpoena = 119,
    Foreign_Attorney_Signed_Trial_Subpoena = 120,
    Local_Executed_Trial_Subpoena = 121,
    Foreign_Executed_Trial_Subpoena = 122,
    Local_Other_Subpoena = 123,
    Foreign_Other_Subpoena = 124,
    Local_Attorney_Signed_Other_Subpoena = 125,
    Foreign_Attorney_Signed_Other_Subpoena = 126,
    Local_Executed_Other_Subpoena = 127,
    Foreign_Executed_Other_Subpoena = 128,
}

export enum DocumentQueueAssignmentTypesEnums {
    Claims = 1,
    Services = 2,
    Physicians = 3,
}

export enum UserRoleIdEnums {
    Admin = 1,
    Accounting = 2,
    RetrievalsAdmin = 14,
    BDA = 51,
}

export enum AddressBookTypeIdEnums {
    Claimant_physicians_and_medical_facilities = 1,
    Claimant_employers = 2,
    Claimant_schools = 3,
    Government_offices = 4,
    Physician_panel = 5,
    Expert_panel = 6,
    Carrier = 7,
    Attorney = 8,
    Translation_Service = 9,
    Background_Investigators = 10,
    Transportation_Providers = 11,
    Miscellaneous = 12,
    Courts = 13,
    Process_Server = 14,
    Rental_Facility = 15,
    Transcription_Service = 16,
}

export enum MetaAddressBookTypeIdEnums {
    Individual = 1,
    Facility_Organization = 2,
}

export enum AddressTypeIdEnums {
    CorrespondenceAddress = 1,
    ExamLocation = 2,
    Home_AlternateAddress = 3,
    OldAddress = 4,
    FilmAddress = 5,
    PaymentAddress = 6,
}

export enum PhysicianStatusesIdEnums {
    Introductory_Letter_Sent = 1,
    Documents_Received_Pending_License_Verification = 2,
    Trial_Period = 3,
    Active = 4,
    Active_Pending_License_Verification = 5,
    Inactive = 6,
    DoesNotDoIMES = 7,
    PreliminaryCallConducted = 8,
    ByRequestOnly = 9,
    Trial_Period_Pending_License_Verification = 11,
}

export enum ServiceStatusIdEnums {
    Record_Retrievals_HIPAA_Processing_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax = 13,
    Record_Retrievals_HIPAA_Processing_Pending_Bill_From_Facility = 14,
    Record_Retrievals_HIPAA_Processing_Bill_Received_Pending_Payment = 15,
    Record_Retrievals_HIPAA_Processing_Bill_paid_pending_check_clearance = 16,
    Record_Retrievals_HIPAA_Processing_Facility_Requires_Subpoena_Pending_Receipt_From_Dc = 17,
    Record_Retrievals_HIPAA_Processing_Complete_To_Be_Invoiced = 18,
    Record_Retrievals_HIPAA_Processing_Complete = 19,
    Record_Retrievals_HIPAA_Processing_Closed_Without_Service = 20,
    Record_Retrievals_HIPAA_Processing_Service_Cancelled_By_Defense_Counsel = 21,
    Record_Retrievals_HIPAA_Processing_Duplicate_Closed = 22,
    Record_Retrievals_HIPAA_Processing_Medical_Records_Received_Pending_Submission_To_Client = 81,
    Record_Retrievals_HIPAA_Processing_Suspended_Until_Further_Notice = 134,
    Record_Retrievals_HIPAA_Processing_Waiting_For_Payment_Authorization = 225,
    Record_Retrievals_HIPAA_Processing_Waiting_For_Medical_Records_Films = 226,
    Record_Retrievals_HIPAA_Processing_Cancelled = 227,
    Record_Retrievals_HIPAA_Processing_Waiting_For_Revised_Az = 240,
    IME_Scheduled_Send_Letter_Notice = 241,
    IME_Pending_Confirmation_Of_Notice_Received = 256,
    IME_Scheduled_Pending_Confirmation_Of_Medical_Records_Received = 261,
    IME_Report_Pending_Additional_Records = 262,
    IME_Rescheduled_Send_Notification_Of_Appointment = 263,
    IME_Rescheduled_Pending_Confirmation_Of_Notification_Received = 264,
    IME_Rescheduled_Pending_Confirmation_Of_Appointment = 265,
    IME_Rescheduled_Pending_Confirmation_Of_Attendance = 266,
    IME_Check_cleared_Awaiting_Report = 267,
    IME_Cancelled = 228,
    IME_Pending_Prepayment = 215,
    IME_No_Show_Manual = 209,
    IME_Pending_Recruitment = 220,
    IME_Scheduled_Pending_Confirmation_Of_Attendance = 79,
    IME_Bill_Received_Pending_Payment = 181,
    IME_Bill_Processed_Pending_Payment = 193,
    IME_Draft_Report_Received = 205,
    IME_Suspended_Until_Further_Notice = 1,
    IME_Pending_Scheduling = 2,
    IME_Scheduled_Pending_Receipt_Of_Records = 3,
    IME_Scheduled_Pending_Compilation_Of_Records = 4,
    IME_Scheduled_Pending_Confirmation_Of_Appointment = 5,
    IME_Pending_Rescheduling = 6,
    IME_Awaiting_Report = 7,
    IME_Awaiting_Report_Revisions = 8,
    IME_Complete_To_Be_Invoiced = 9,
    IME_Complete = 10,
    IME_Closed_Without_Service = 11,
    IME_Service_Cancelled_By_Defense_Counsel = 12,
    IME_Waiting_For_Invoice_From_Facility_Doctor = 143,
    IME_Bill_Paid_Pending_Check_Clearance = 155,
    Radiology_Review_Bill_Paid_Pending_Check_Clearance = 156,
    Radiology_Review_Waiting_For_Invoice_From_Facility_Doctor = 144,
    Radiology_Review_Suspended_Until_Further_Notice = 135,
    Radiology_Review_Bill_Processed_Pending_Payment = 194,
    Radiology_Review_Bill_Received_Pending_Payment = 182,
    Radiology_Review_Cancelled = 229,
    Radiology_Review_Pending_Receipt_Of_Films_From_Client = 23,
    Radiology_Review_Films_Forwarded_To_Expert_Pending_Report = 24,
    Radiology_Review_Awaiting_Report_Revisions = 25,
    Radiology_Review_Complete_To_Be_Invoiced = 26,
    Radiology_Review_Complete = 27,
    Radiology_Review_Closed_Without_Service = 28,
    Radiology_Review_Service_Cancelled_By_Defense_Counsel = 29,
    Radiology_Review_Duplicate_Closed = 30,
    Radiology_Review_Pending_Receipt_Of_Films_From_Facility = 268,
    Radiology_Review_Check_Cleared_Pending_Report = 269,
    Radiology_Review_Addendum_related_service = 316,
    Radiology_Review_Expert_Consultation_related_service = 344,
    Radiology_Review_IME_re_exam_related_service = 353,
    Radiology_Review_IME_related_service = 362,
    Radiology_Review_Record_Review_Related_Service = 783,
    Record_Review_Draft_Report_Received = 270,
    Record_Review_Check_Cleared_Awaiting_Report = 271,
    Record_Review_Physician_Selected_Waiting_For_Authorization_To_Move_Forward = 303,
    Record_Review_Scheduled_Pending_Receipt_Of_Records = 31,
    Record_Review_Scheduled_Pending_Compilation_Of_Records = 32,
    Record_Review_Records_Compiled_Awaiting_Report = 33,
    Record_Review_Awaiting_Report_Revision = 34,
    Record_Review_Complete_To_Be_Invoiced = 35,
    Record_Review_Complete = 36,
    Record_Review_Closed_Without_Service = 37,
    Record_Review_Service_Cancelled_By_Defense_Counsel = 38,
    Record_Review_Duplicate_Closed = 39,
    Record_Review_Cancelled = 230,
    Record_Review_Bill_Received_Pending_Payment = 183,
    Record_Review_Awaiting_Report = 80,
    Record_Review_Bill_Processed_Pending_Payment = 195,
    Record_Review_Suspended_Until_Further_Notice = 136,
    Record_Review_Waiting_For_Invoice_From_Facility_Doctor = 145,
    Record_Review_Bill_Paid_Pending_Check_Clearance = 157,
    Record_Review_Requested_pending_physician_selection = 374,
    Billing_Desk_Review_Suspended_Until_Further_Notice = 137,
    Billing_Desk_Review_Cancelled = 231,
    Billing_Desk_Review_Scheduled_Pending_Receipt_Of_Records = 40,
    Billing_Desk_Review_Scheduled_Pending_Review_Of_Records = 41,
    Billing_Desk_Review_Report_Prepared_Awaiting_Review_And_Revision = 42,
    Billing_Desk_Review_Complete_To_Be_Invoiced = 43,
    Billing_Desk_Review_Complete = 44,
    Billing_Desk_Review_Closed_Without_Service = 45,
    Billing_Desk_Review_Service_Cancelled_By_Defense_Counsel = 46,
    Billing_Desk_Review_Duplicate_Closed = 47,
    CRS_Full_Day_Testimony_Dates_Requested_Pending_Confirmation = 48,
    CRS_Full_Day_Testimony_Dates_Confirmed_Pending_Payment = 49,
    CRS_Full_Day_Testimony_Payment_Forwarded_To_Be_Invoiced = 50,
    CRS_Full_Day_Testimony_Complete_Testimony_Completed = 51,
    CRS_Full_Day_Testimony_Complete_Testimony_Cancelled = 52,
    CRS_Full_Day_Testimony_Closed_Without_Service = 53,
    CRS_Full_Day_Testimony_Service_Cancelled_By_Defense_Counsel = 54,
    CRS_Full_Day_Testimony_Duplicate_closed = 55,
    CRS_Full_Day_Testimony_Cancelled = 232,
    CRS_Full_Day_Testimony_Suspended_Until_Further_Notice = 138,
    CRS_Full_Day_Testimony_Bill_Paid_Pending_Check_Clearance = 159,
    CRS_Full_Day_Testimony_Waiting_For_Invoice_From_Facility_Doctor = 147,
    CRS_Half_Day_Testimony_Waiting_For_Invoice_From_Facility_Doctor = 148,
    CRS_Half_Day_Testimony_Bill_Paid_Pending_Check_Clearance = 160,
    CRS_Half_Day_Testimony_Suspended_Until_Further_Notice = 139,
    CRS_Half_Day_Testimony_Cancelled = 233,
    CRS_Half_Day_Testimony_Dates_Requested_Pending_Confirmation = 56,
    CRS_Half_Day_Testimony_Dates_Confirmed_Pending_Payment = 57,
    CRS_Half_Day_Testimony_Payment_Forwarded_To_Be_Invoiced = 58,
    CRS_Half_Day_Testimony_Complete_Testimony_Completed = 59,
    CRS_Half_Day_Testimony_Complete_Testimony_Cancelled = 60,
    CRS_Half_Day_Testimony_Closed_Without_Service = 61,
    CRS_Half_Day_Testimony_Service_Cancelled_By_Defense_Counsel = 62,
    CRS_Half_Day_Testimony_Duplicate_Closed = 63,
    CRS_Deposition_Dates_Requested_Pending_Confirmation = 64,
    CRS_Deposition_Dates_Confirmed_Pending_Payment = 65,
    CRS_Deposition_Payment_Forwarded_To_Be_Invoiced = 66,
    CRS_Deposition_Complete_Testimony_Completed = 67,
    CRS_Deposition_Complete_Testimony_Cancelled = 68,
    CRS_Deposition_Closed_Without_Service = 69,
    CRS_Deposition_Service_Cancelled_By_Defense_Counsel = 70,
    CRS_Deposition_Duplicate_Closed = 71,
    CRS_Deposition_Cancelled = 234,
    CRS_Deposition_Suspended_Until_Further_Notice = 140,
    CRS_Deposition_Bill_Paid_Pending_Check_Clearance = 161,
    CRS_Deposition_Waiting_For_Invoice_From_Facility_Doctor = 149,
    CRS_Conference_Call_Suspended_Until_Further_Notice = 141,
    CRS_Conference_Call_Pending_Scheduling = 73,
    CRS_Conference_Call_Conference_Call_Confirmed = 74,
    CRS_Conference_Call_Completed = 75,
    CRS_Conference_Call_Cancelled = 306,
    CRS_Conference_Call_Scheduled_Confirmation_Forwarded_To_Physician_And_Defense_Counsel = 272,
    CRS_Conference_Call_Completed_To_Be_Invoiced = 273,
    CRS_Conference_Call_Prepayment_Required = 274,
    CRS_Conference_Call_Charges_Paid_Pending_Check_Clearance = 275,
    CRS_Conference_Call_Check_Cleared = 276,
    IME_Re_Exam_Scheduled_Send_Letter_Notice = 277,
    IME_Re_Exam_Scheduled_Pending_Confirmation_Of_Medical_Records_Received = 278,
    IME_Re_Exam_Report_Pending_Additional_Records = 279,
    IME_Re_Exam_Rescheduled_Send_Notification_Of_Appointment = 280,
    IME_Re_Exam_Rescheduled_Pending_Confirmation_Of_Notification_Received = 281,
    IME_Re_Exam_Rescheduled_Pending_Confirmation_Of_Appointment = 282,
    IME_Re_Exam_Rescheduled_Pending_Confirmation_Of_Attendance = 283,
    IME_Re_Exam_Check_cleared_Awaiting_Report = 284,
    IME_Re_Exam_Pending_Confirmation_Of_Notice_Received = 257,
    IME_Re_Exam_Suspended_Until_Further_Notice = 82,
    IME_Re_Exam_Pending_Scheduling = 83,
    IME_Re_Exam_Scheduled_Pending_Receipt_Of_Records = 84,
    IME_Re_Exam_Scheduled_Pending_Compilation_Of_Records = 85,
    IME_Re_Exam_Scheduled_Pending_Confirmation_Of_Appointment = 86,
    IME_Re_Exam_Pending_Rescheduling = 87,
    IME_Re_Exam_Awaiting_Report = 88,
    IME_Re_Exam_Awaiting_Report_Revisions = 89,
    IME_Re_Exam_Complete_To_Be_Invoiced = 90,
    IME_Re_Exam_Complete = 91,
    IME_Re_Exam_Closed_Without_Service = 92,
    IME_Re_Exam_Service_Cancelled_By_Defense_Counsel = 93,
    IME_Re_Exam_Waiting_For_Invoice_From_Facility_Doctor = 151,
    IME_Re_Exam_Bill_Paid_Pending_Check_Clearance = 163,
    IME_Re_Exam_Cancelled = 236,
    IME_Re_Exam_No_Show_Manual = 210,
    IME_Re_Exam_Pending_Prepayment = 216,
    IME_Re_Exam_Bill_Processed_Pending_Payment = 201,
    IME_Re_Exam_Draft_Report_Received = 206,
    IME_Re_Exam_Scheduled_Pending_Confirmation_Of_Attendance = 130,
    IME_Re_Exam_Bill_Received_Pending_Payment = 189,
    IME_Addendum_Bill_Received_Pending_Payment = 190,
    IME_Addendum_Draft_Report_Received = 207,
    IME_Addendum_Bill_Processed_Pending_Payment = 202,
    IME_Addendum_Pending_Prepayment = 217,
    IME_Addendum_Cancelled = 237,
    IME_Addendum_Bill_Paid_Pending_Check_Clearance = 164,
    IME_Addendum_Waiting_For_Invoice_From_Facility_Doctor = 152,
    IME_Addendum_Suspended_Until_Further_Notice = 94,
    IME_Addendum_Scheduled_Pending_Receipt_Of_Records = 96,
    IME_Addendum_Scheduled_Pending_Compilation_Of_Records = 97,
    IME_Addendum_Scheduled_Pending_Confirmation_Of_Appointment = 98,
    IME_Addendum_Awaiting_Report = 100,
    IME_Addendum_Awaiting_Report_Revisions = 101,
    IME_Addendum_Complete_To_Be_Invoiced = 102,
    IME_Addendum_Complete = 103,
    IME_Addendum_Closed_Without_Service = 104,
    IME_Addendum_Service_Cancelled_By_Defense_Counsel = 105,
    IME_Addendum_Check_Cleared_Awaiting_Report = 285,
    IME_Addendum_IME_expert_consultation_related_service = 349,
    IME_Addendum_IME_re_exam_related_service = 358,
    IME_Addendum_IME_related_service = 367,
    IME_Addendum_Record_Review_Related_Service = 784,
    IME_Expert_Consultation_Scheduled_Send_Letter_Notice = 286,
    IME_Expert_Consultation_Scheduled_Pending_Confirmation_Of_Medical_Records_Received = 287,
    IME_Expert_Consultation_Report_Pending_Additional_Records = 288,
    IME_Expert_Consultation_Rescheduled_Send_Notification_Of_Appointment = 289,
    IME_Expert_Consultation_Rescheduled_Pending_Confirmation_Of_Notification_Received = 290,
    IME_Expert_Consultation_Rescheduled_Pending_Confirmation_Of_Appointment = 291,
    IME_Expert_Consultation_Rescheduled_Pending_Confirmation_Of_Attendance = 292,
    IME_Expert_Consultation_Check_cleared_Awaiting_Report = 293,
    IME_Expert_Consultation_Pending_Confirmation_Of_Notice_Received = 259,
    IME_Expert_Consultation_Cancelled = 304,
    IME_Expert_Consultation_Suspended_Until_Further_Notice = 106,
    IME_Expert_Consultation_Pending_Scheduling = 107,
    IME_Expert_Consultation_Scheduled_Pending_Receipt_Of_Records = 108,
    IME_Expert_Consultation_Scheduled_Pending_Compilation_Of_Records = 109,
    IME_Expert_Consultation_Scheduled_Pending_Confirmation_Of_Appointment = 110,
    IME_Expert_Consultation_Pending_Rescheduling = 111,
    IME_Expert_Consultation_Awaiting_Report = 112,
    IME_Expert_Consultation_Awaiting_Report_Revisions = 113,
    IME_Expert_Consultation_Complete_To_Be_Invoiced = 114,
    IME_Expert_Consultation_Complete = 115,
    IME_Expert_Consultation_Closed_Without_Service = 116,
    IME_Expert_Consultation_Service_Cancelled_By_Defense_Counsel = 117,
    IME_Expert_Consultation_Waiting_For_Invoice_From_Facility_Doctor = 153,
    IME_Expert_Consultation_Bill_Paid_Pending_Check_Clearance = 165,
    IME_Expert_Consultation_Pending_Prepayment = 218,
    IME_Expert_Consultation_No_Show_Manual = 211,
    IME_Expert_Consultation_Pending_Recruitment = 223,
    IME_Expert_Consultation_Bill_Processed_Pending_Payment = 203,
    IME_Expert_Consultation_Draft_Report_Received = 208,
    IME_Expert_Consultation_Bill_Received_Pending_Payment = 191,
    IME_Expert_Consultation_Scheduled_Pending_Confirmation_Of_Attendance = 132,
    IME_Conference_Call_Cancelled = 305,
    IME_Conference_Call_Scheduled_Confirmation_Forwarded_To_Physician_And_Defense_Counsel = 294,
    IME_Conference_Call_Completed_To_Be_Invoiced = 295,
    IME_Conference_Call_Prepayment_Required = 296,
    IME_Conference_Call_Charges_Paid_Pending_Check_Clearance = 297,
    IME_Conference_Call_Check_Cleared = 298,
    IME_Conference_Call_Pending_Scheduling = 299,
    IME_Conference_Call_Conference_Call_Confirmed = 300,
    IME_Conference_Call_Completed = 301,
    IME_Conference_Call_Suspended_Until_Further_Notice = 302,
    CRS_Full_Day_Testimony_Bill_Received_Pending_Payment = 308,
    CRS_Half_Day_Testimony_Bill_Received_Pending_Payment = 309,
    CRS_Deposition_Bill_Received_Pending_Payment = 310,
    CRS_Conference_Call_Bill_Received_Pending_Payment = 311,
    IME_Conference_Call_Bill_Received_Pending_Payment = 312,
    Billing_Desk_Review_Bill_Received_Pending_Payment = 307,
    IME_Conference_Call_IME_expert_consultation_related_service = 351,
    IME_Conference_Call_IME_re_exam_related_service = 360,
    IME_Conference_Call_IME_related_service = 369,
    IME_Conference_Call_Record_Review_Related_Service = 785,
    WC_IME_Pending_Scheduling = 395,
    WC_IME_Scheduled_Pending_Receipt_Of_Records = 396,
    WC_IME_Scheduled_Pending_Compilation_Of_Records = 397,
    WC_IME_Scheduled_Pending_Confirmation_Of_Appointment = 398,
    WC_IME_Pending_Rescheduling = 399,
    WC_IME_Awaiting_Report = 400,
    WC_IME_Awaiting_Report_Revisions = 401,
    WC_IME_Scheduled_Pending_Confirmation_Of_Attendance = 406,
    WC_IME_Waiting_For_Invoice_From_Facility_Doctor = 407,
    WC_IME_Bill_Paid_Pending_Check_Clearance = 408,
    WC_IME_Bill_Received_Pending_Payment = 409,
    WC_IME_Bill_Processed_Pending_Payment = 410,
    WC_IME_No_Show_Manual = 412,
    WC_IME_Pending_Prepayment = 413,
    WC_IME_Pending_Recruitment = 414,
    WC_IME_Scheduled_Send_Letter_Notice = 416,
    WC_IME_Pending_Confirmation_Of_Notice_Received = 417,
    WC_IME_Scheduled_Pending_Confirmation_Of_Medical_Records_Received = 418,
    WC_IME_Report_Pending_Additional_Records = 419,
    WC_IME_Rescheduled_Send_Notification_Of_Appointment = 420,
    WC_IME_Rescheduled_Pending_Confirmation_Of_Notification_Received = 421,
    WC_IME_Rescheduled_Pending_Confirmation_Of_Appointment = 422,
    WC_IME_Rescheduled_Pending_Confirmation_Of_Attendance = 423,
    WC_IME_Check_cleared_Awaiting_Report = 424,
    WC_IME_Cancelled = 415,
    WC_IME_Suspended_Until_Further_Notice = 394,
    WC_IME_Complete_To_Be_Invoiced = 402,
    WC_IME_Complete = 403,
    WC_IME_Draft_Report_Received = 427,
    WC_IME_Closed_Without_Service = 404,
    WC_IME_Service_Cancelled_By_Defense_Counsel = 405,
    Auto_IME_Suspended_until_further_notice = 434,
    Auto_IME_Pending_scheduling = 435,
    Auto_IME_Scheduled_pending_receipt_of_records = 436,
    Auto_IME_Scheduled_pending_compilation_of_records = 437,
    Auto_IME_Scheduled_pending_confirmation_of_appointment = 438,
    Auto_IME_Pending_rescheduling = 439,
    Auto_IME_Awaiting_report = 440,
    Auto_IME_Awaiting_report_revisions = 441,
    Auto_IME_Complete_to_be_invoiced = 442,
    Auto_IME_Complete = 443,
    Auto_IME_Closed_without_service = 444,
    Auto_IME_Service_cancelled_by_defense_counsel = 445,
    Auto_IME_Scheduled_pending_confirmation_of_attendance = 446,
    Auto_IME_Waiting_for_invoice_from_facility_doctor = 447,
    Auto_IME_Bill_paid_pending_check_clearance = 448,
    Auto_IME_Bill_received_pending_payment = 449,
    Auto_IME_Bill_processed_pending_payment = 450,
    Auto_IME_Report_received_from_physician_pending_audit = 451,
    Auto_IME_No_show_manual = 452,
    Auto_IME_Pending_prepayment = 453,
    Auto_IME_Pending_recruitment = 454,
    Auto_IME_Cancelled = 455,
    Auto_IME_Scheduled_send_letter_notice = 456,
    Auto_IME_Pending_confirmation_of_notice_received = 457,
    Auto_IME_Scheduled_pending_confirmation_of_medical_records_received = 458,
    Auto_IME_Pending_additional_records_report_will_be_held_pending_receipt = 459,
    Auto_IME_Rescheduled_send_notification_of_appointment = 460,
    Auto_IME_Rescheduled_pending_confirmation_of_notification_received = 461,
    Auto_IME_Rescheduled_pending_confirmation_of_appointment = 462,
    Auto_IME_Rescheduled_pending_confirmation_of_attendance = 463,
    Auto_IME_Check_cleared_awaiting_report = 464,
    Auto_IME_Addendum_related_service = 465,
    Auto_IME_Case_settled_closed_without_service = 466,
    Auto_IME_Draft_report_forwarded_to_defense_counsel = 467,
    Auto_IME_expert_consultation_related_service = 468,
    Auto_IME_re_exam_related_service = 469,
    Auto_IME_related_service = 470,
    Auto_IME_Rescheduled_pending_receipt_of_records = 471,
    Auto_IME_Awaiting_report_pending_payment = 472,
    Auto_IME_Requested_pending_physician_selection = 473,
    WC_Review_Scheduled_pending_receipt_of_records = 474,
    WC_Review_Scheduled_pending_compilation_of_records = 475,
    WC_Review_Scheduled_pending_confirmation_of_appointment = 476,
    WC_Review_Awaiting_report_revisions = 477,
    WC_Review_Complete_to_be_invoiced = 478,
    WC_Review_Complete = 479,
    WC_Review_Closed_without_service = 480,
    WC_Review_Service_cancelled_by_defense_counsel = 481,
    WC_Review_Duplicate_closed = 482,
    WC_Review_Awaiting_report = 483,
    WC_Review_Awaiting_report_pending_payment = 484,
    WC_Review_Suspended_until_further_notice = 485,
    WC_Review_Waiting_for_invoice_from_facility_doctor = 486,
    WC_Review_Bill_paid_pending_check_clearance = 487,
    WC_Review_Bill_received_pending_payment = 488,
    WC_Review_Bill_processed_pending_payment = 489,
    WC_Review_Cancelled = 490,
    WC_Review_Report_received_from_physician_pending_audit = 491,
    WC_Review_Pending_prepayment = 492,
    WC_Review_Check_cleared_awaiting_report = 493,
    WC_Review_Physician_selected_waiting_for_authorization_to_move_forward = 494,
    WC_Review_Addendum_related_service = 495,
    WC_Review_Case_settled_closed_without_service = 496,
    WC_Review_Draft_report_forwarded_to_defense_counsel = 497,
    WC_Review_IME_expert_consultation_related_service = 498,
    WC_Review_IME_re_exam_related_service = 499,
    WC_Review_IME_related_service = 500,
    WC_Review_Requested_pending_physician_selection = 501,
    WC_Review_Rescheduled_pending_receipt_of_records = 502,
    Auto_Review_Scheduled_pending_receipt_of_records = 503,
    Auto_Review_Scheduled_pending_compilation_of_records = 504,
    Auto_Review_Scheduled_pending_confirmation_of_appointment = 505,
    Auto_Review_Awaiting_report_revisions = 506,
    Auto_Review_Complete_to_be_invoiced = 507,
    Auto_Review_Complete = 508,
    Auto_Review_Closed_without_service = 509,
    Auto_Review_Service_cancelled_by_defense_counsel = 510,
    Auto_Review_Duplicate_closed = 511,
    Auto_Review_Awaiting_report = 512,
    Auto_Review_Awaiting_report_pending_payment = 513,
    Auto_Review_Suspended_until_further_notice = 514,
    Auto_Review_Waiting_for_invoice_from_facility_doctor = 515,
    Auto_Review_Bill_paid_pending_check_clearance = 516,
    Auto_Review_Bill_received_pending_payment = 517,
    Auto_Review_Bill_processed_pending_payment = 518,
    Auto_Review_Cancelled = 519,
    Auto_Review_Report_received_from_physician_pending_audit = 520,
    Auto_Review_Pending_prepayment = 521,
    Auto_Review_Check_cleared_awaiting_report = 522,
    Auto_Review_Physician_selected_waiting_for_authorization_to_move_forward = 523,
    Auto_Review_Addendum_related_service = 524,
    Auto_Review_Case_settled_closed_without_service = 525,
    Auto_Review_Draft_report_forwarded_to_defense_counsel = 526,
    Auto_Review_IME_expert_consultation_related_service = 527,
    Auto_Review_IME_re_exam_related_service = 528,
    Auto_Review_IME_related_service = 529,
    Auto_Review_Requested_pending_physician_selection = 530,
    Auto_Review_Rescheduled_pending_receipt_of_records = 531,
    WC_IME_Re_Exam_Suspended_until_further_notice = 532,
    WC_IME_Re_Exam_Pending_scheduling = 533,
    WC_IME_Re_Exam_Scheduled_pending_receipt_of_records = 534,
    WC_IME_Re_Exam_Scheduled_pending_compilation_of_records = 535,
    WC_IME_Re_Exam_Scheduled_pending_confirmation_of_appointment = 536,
    WC_IME_Re_Exam_Pending_rescheduling = 537,
    WC_IME_Re_Exam_Awaiting_report = 538,
    WC_IME_Re_Exam_Awaiting_report_revisions = 539,
    WC_IME_Re_Exam_Complete_to_be_invoiced = 540,
    WC_IME_Re_Exam_Complete = 541,
    WC_IME_Re_Exam_Closed_without_service = 542,
    WC_IME_Re_Exam_Service_cancelled_by_defense_counsel = 543,
    WC_IME_Re_Exam_Scheduled_pending_confirmation_of_attendance = 544,
    WC_IME_Re_Exam_Waiting_for_invoice_from_facility_doctor = 545,
    WC_IME_Re_Exam_Bill_paid_pending_check_clearance = 546,
    WC_IME_Re_Exam_Bill_received_pending_payment = 547,
    WC_IME_Re_Exam_Bill_processed_pending_payment = 548,
    WC_IME_Re_Exam_Report_received_from_physician_pending_audit = 549,
    WC_IME_Re_Exam_No_show_manual = 550,
    WC_IME_Re_Exam_Pending_prepayment = 551,
    WC_IME_Re_Exam_Cancelled = 552,
    WC_IME_Re_Exam_Pending_confirmation_of_notice_received = 553,
    WC_IME_Re_Exam_Scheduled_send_letter_notice = 554,
    WC_IME_Re_Exam_Scheduled_pending_confirmation_of_medical_records_received = 555,
    WC_IME_Re_Exam_Pending_additional_records_report_will_be_held_pending_receipt = 556,
    WC_IME_Re_Exam_Rescheduled_send_notification_of_appointment = 557,
    WC_IME_Re_Exam_Rescheduled_pending_confirmation_of_notification_received = 558,
    WC_IME_Re_Exam_Rescheduled_pending_confirmation_of_appointment = 559,
    WC_IME_Re_Exam_Rescheduled_pending_confirmation_of_attendance = 560,
    WC_IME_Re_Exam_Check_cleared_awaiting_report = 561,
    WC_IME_Re_Exam_Addendum_related_service = 562,
    WC_IME_Re_Exam_Case_settled_closed_without_service = 563,
    WC_IME_Re_Exam_Draft_report_forwarded_to_defense_counsel = 564,
    WC_IME_Re_Exam_IME_expert_consultation_related_service = 565,
    WC_IME_Re_Exam_IME_re_exam_related_service = 566,
    WC_IME_Re_Exam_IME_related_service = 567,
    WC_IME_Re_Exam_Rescheduled_pending_receipt_of_records = 568,
    WC_IME_Re_Exam_Awaiting_report_pending_payment = 569,
    WC_IME_Re_Exam_Requested_pending_physician = 570,
    WC_Radiology_Review_Pending_receipt_of_films_from_client = 571,
    WC_Radiology_Review_Films_forwarded_to_expert_pending_report = 572,
    WC_Radiology_Review_Awaiting_report_revisions = 573,
    WC_Radiology_Review_Complete_to_be_invoiced = 574,
    WC_Radiology_Review_Complete = 575,
    WC_Radiology_Review_Closed_without_service = 576,
    WC_Radiology_Review_Service_cancelled_by_defense_counsel = 577,
    WC_Radiology_Review_Duplicate_closed = 578,
    WC_Radiology_Review_Suspended_until_further_notice = 579,
    WC_Radiology_Review_Waiting_for_invoice_from_facility_doctor = 580,
    WC_Radiology_Review_Bill_paid_pending_check_clearance = 581,
    WC_Radiology_Review_Bill_received_pending_payment = 582,
    WC_Radiology_Review_Bill_processed_pending_payment = 583,
    WC_Radiology_Review_Cancelled = 584,
    WC_Radiology_Review_Pending_receipt_of_films_from_facility_facilities = 585,
    WC_Radiology_Review_Check_cleared_pending_report = 586,
    WC_Radiology_Review_Report_received_from_physician_pending_audit_ = 587,
    WC_Radiology_Review_Addendum_related_service = 588,
    WC_Radiology_Review_Case_settled_closed_without_service = 589,
    WC_Radiology_Review_Draft_report_forwarded_to_defense_counsel = 590,
    WC_Radiology_Review_IME_expert_consultation_related_service_ = 591,
    WC_Radiology_Review_IME_re_exam_related_service = 592,
    WC_Radiology_Review_IME_related_service = 593,
    WC_Radiology_Review_Rescheduled_pending_receipt_of_records = 594,
    WC_Radiology_Review_Record_Review_Related_Service = 786,
    WC_FCE_Suspended_until_further_notice = 595,
    WC_FCE_Pending_scheduling = 596,
    WC_FCE_Scheduled_pending_receipt_of_records = 597,
    WC_FCE_Scheduled_pending_compilation_of_records = 598,
    WC_FCE_Scheduled_pending_confirmation_of_appointment = 599,
    WC_FCE_Pending_rescheduling = 600,
    WC_FCE_Awaiting_report = 601,
    WC_FCE_Awaiting_report_revisions = 602,
    WC_FCE_Complete_to_be_invoiced = 603,
    WC_FCE_Complete = 604,
    WC_FCE_Closed_without_service = 605,
    WC_FCE_Service_cancelled_by_defense_counsel = 606,
    WC_FCE_Scheduled_pending_confirmation_of_attendance = 607,
    WC_FCE_Waiting_for_invoice_from_facility_doctor = 608,
    WC_FCE_Bill_paid_pending_check_clearance = 609,
    WC_FCE_Bill_received_pending_payment = 610,
    WC_FCE_Bill_processed_pending_payment = 611,
    WC_FCE_Report_received_from_physician_pending_audit = 612,
    WC_FCE_No_show_manual = 613,
    WC_FCE_Pending_prepayment = 614,
    WC_FCE_Pending_recruitment = 615,
    WC_FCE_Cancelled = 616,
    WC_FCE_Scheduled_send_letter_notice = 617,
    WC_FCE_Pending_confirmation_of_notice_received = 618,
    WC_FCE_Scheduled_pending_confirmation_of_medical_records_received = 619,
    WC_FCE_Pending_additional_records_report_will_be_held_pending_receipt = 620,
    WC_FCE_Rescheduled_send_notification_of_appointment = 621,
    WC_FCE_Rescheduled_pending_confirmation_of_notification_received = 622,
    WC_FCE_Rescheduled_pending_confirmation_of_appointment = 623,
    WC_FCE_Rescheduled_pending_confirmation_of_attendance = 624,
    WC_FCE_Check_cleared_awaiting_report = 625,
    WC_FCE_Addendum_related_service = 626,
    WC_FCE_Case_settled_closed_without_service = 627,
    WC_FCE_Draft_report_forwarded_to_defense_counsel = 628,
    WC_FCE_IME_expert_consultation_related_service = 629,
    WC_FCE_IME_re_exam_related_service = 630,
    WC_FCE_IME_related_service = 631,
    WC_FCE_Rescheduled_pending_receipt_of_records = 632,
    WC_FCE_Awaiting_report_pending_payment = 633,
    WC_FCE_Requested_pending_physician_select = 634,
    Auto_Peer_Scheduled_pending_compilation_of_records = 635,
    Auto_Peer_Scheduled_pending_confirmation_of_appointment = 636,
    Auto_Peer_Awaiting_report_revisions = 637,
    Auto_Peer_Awaiting_report = 643,
    Auto_Peer_Awaiting_report_pending_payment = 644,
    Auto_Peer_Waiting_for_invoice_from_facility_doctor = 647,
    Auto_Peer_Bill_paid_pending_check_clearance = 648,
    Auto_Peer_Bill_received_pending_payment = 649,
    Auto_Peer_Bill_processed_pending_payment = 650,
    Auto_Peer_Report_received_from_physician_pending_audit = 652,
    Auto_Peer_Pending_prepayment = 653,
    Auto_Peer_Check_cleared_awaiting_report = 654,
    Auto_Peer_Physician_selected_waiting_for_authorization_to_move_forward = 655,
    Auto_Peer_Addendum_related_service = 656,
    Auto_Peer_Draft_report_forwarded_to_defense_counsel = 658,
    Auto_Peer_Requested_pending_physician_selection = 662,
    Auto_Peer_Rescheduled_pending_receipt_of_records = 663,
    Auto_Peer_Scheduled_pending_receipt_of_records = 664,
    WC_Addendum_Suspended_until_further_notice = 665,
    WC_Addendum_Scheduled_pending_receipt_of_records = 666,
    WC_Addendum_Scheduled_pending_compilation_of_records = 667,
    WC_Addendum_Scheduled_pending_confirmation_of_appointment = 668,
    WC_Addendum_Awaiting_report = 669,
    WC_Addendum_Awaiting_report_revisions = 670,
    WC_Addendum_Complete_to_be_invoiced = 671,
    WC_Addendum_Complete = 672,
    WC_Addendum_Closed_without_service = 673,
    WC_Addendum_Service_cancelled_by_defense_counsel = 674,
    WC_Addendum_Waiting_for_invoice_from_facility_doctor = 675,
    WC_Addendum_Bill_paid_pending_check_clearance = 676,
    WC_Addendum_Bill_received_pending_payment = 677,
    WC_Addendum_Bill_processed_pending_payment = 678,
    WC_Addendum_Report_received_from_physician_pending_audit = 679,
    WC_Addendum_Pending_prepayment = 680,
    WC_Addendum_Cancelled = 681,
    WC_Addendum_Check_cleared_awaiting_report = 682,
    WC_Addendum_Case_settled_closed_without_service = 683,
    WC_Addendum_Draft_report_forwarded_to_defense_counsel = 684,
    WC_Addendum_IME_expert_consultation_related_service = 685,
    WC_Addendum_IME_re_exam_related_service = 686,
    WC_Addendum_IME_related_service = 687,
    WC_Addendum_Rescheduled_pending_receipt_of_records = 688,
    WC_Addendum_Awaiting_report_pending_payment = 689,
    WC_Addendum_Requested_pending_physician_selection = 690,
    WC_Addendum_Record_Review_Related_Service = 787,
    WC_Conference_Call_Scheduled_confirmation_forwarded_to_physician_and_defense_counsel = 691,
    WC_Conference_Call_Complete_to_be_invoiced = 692,
    WC_Conference_Call_Prepayment_required = 693,
    WC_Conference_Call_Charges_paid_pending_check_clearance = 694,
    WC_Conference_Call_Check_cleared = 695,
    WC_Conference_Call_Pending_scheduling = 696,
    WC_Conference_Call_Conference_call_confirmed = 697,
    WC_Conference_Call_Complete = 698,
    WC_Conference_Call_Suspended_until_further_notice = 699,
    WC_Conference_Call_Cancelled = 700,
    WC_Conference_Call_Bill_received_pending_payment = 701,
    WC_Conference_Call_Report_received_from_physician_pending_audit = 702,
    WC_Conference_Call_Addendum_related_service = 703,
    WC_Conference_Call_Case_settled_closed_without_service = 704,
    WC_Conference_Call_Draft_report_forwarded_to_defense_counsel = 705,
    WC_Conference_Call_IME_expert_consultation_related_service = 706,
    WC_Conference_Call_IME_re_exam_related_service = 707,
    WC_Conference_Call_IME_related_service = 708,
    WC_Conference_Call_Rescheduled_pending_receipt_of_records = 709,
    WC_Conference_Call_Awaiting_report_pending_payment = 710,
    WC_Conference_Call_Requested_pending_physician_selection = 711,
    WC_Conference_Call_Record_Review_Related_Service = 788,
    Auto_Radiology_Pending_receipt_of_films_from_client = 712,
    Auto_Radiology_Films_forwarded_to_expert_pending_report = 713,
    Auto_Radiology_Awaiting_report_revisions = 714,
    Auto_Radiology_Complete_to_be_invoiced = 715,
    Auto_Radiology_Complete = 716,
    Auto_Radiology_Closed_without_service = 717,
    Auto_Radiology_Service_cancelled_by_defense_counsel = 718,
    Auto_Radiology_Duplicate_closed = 719,
    Auto_Radiology_Suspended_until_further_notice = 720,
    Auto_Radiology_Waiting_for_invoice_from_facility_doctor = 721,
    Auto_Radiology_Bill_paid_pending_check_clearance = 722,
    Auto_Radiology_Bill_received_pending_payment = 723,
    Auto_Radiology_Bill_processed_pending_payment = 724,
    Auto_Radiology_Cancelled = 725,
    Auto_Radiology_Pending_receipt_of_films_from_facility_facilities = 726,
    Auto_Radiology_Check_cleared_pending_report = 727,
    Auto_Radiology_Report_received_from_physician_pending_audit = 728,
    Auto_Radiology_Addendum_related_service = 729,
    Auto_Radiology_Case_settled_closed_without_service = 730,
    Auto_Radiology_Draft_report_forwarded_to_defense_counsel = 731,
    Auto_Radiology_IME_expert_consultation_related_service = 732,
    Auto_Radiology_IME_re_exam_related_service = 733,
    Auto_Radiology_IME_related_service = 734,
    Auto_Radiology_Rescheduled_pending_receipt_of_records = 735,
    Auto_Radiology_Review_Record_Review_Related_Service = 789,
    Auto_Addendum_Suspended_until_further_notice = 736,
    Auto_Addendum_Scheduled_pending_receipt_of_records = 737,
    Auto_Addendum_Scheduled_pending_compilation_of_records = 738,
    Auto_Addendum_Scheduled_pending_confirmation_of_appointment = 739,
    Auto_Addendum_Awaiting_report = 740,
    Auto_Addendum_Awaiting_report_revisions = 741,
    Auto_Addendum_Complete_to_be_invoiced = 742,
    Auto_Addendum_Complete = 743,
    Auto_Addendum_Closed_without_service = 744,
    Auto_Addendum_Service_cancelled_by_defense_counsel = 745,
    Auto_Addendum_Waiting_for_invoice_from_facility_doctor = 746,
    Auto_Addendum_Bill_paid_pending_check_clearance = 747,
    Auto_Addendum_Bill_received_pending_payment = 748,
    Auto_Addendum_Bill_processed_pending_payment = 749,
    Auto_Addendum_Report_received_from_physician_pending_audit = 750,
    Auto_Addendum_Pending_prepayment = 751,
    Auto_Addendum_Cancelled = 752,
    Auto_Addendum_Check_cleared_awaiting_report = 753,
    Auto_Addendum_Case_settled_closed_without_service = 754,
    Auto_Addendum_Draft_report_forwarded_to_defense_counsel = 755,
    Auto_Addendum_IME_expert_consultation_related_service = 756,
    Auto_Addendum_IME_re_exam_related_service = 757,
    Auto_Addendum_IME_related_service = 758,
    Auto_Addendum_Rescheduled_pending_receipt_of_records = 759,
    Auto_Addendum_Awaiting_report_pending_payment = 760,
    Auto_Addendum_Requested_pending_physician_selection = 761,
    Auto_IME_Addendum_Record_Review_Related_Service = 790,
    Auto_Conference_Scheduled_confirmation_forwarded_to_physician_and_defense_counsel = 762,
    Auto_Conference_Complete_to_be_invoiced = 763,
    Auto_Conference_Prepayment_required = 764,
    Auto_Conference_Charges_paid_pending_check_clearance = 765,
    Auto_Conference_Check_cleared = 766,
    Auto_Conference_Pending_scheduling = 767,
    Auto_Conference_Conference_call_confirmed = 768,
    Auto_Conference_Complete = 769,
    Auto_Conference_Suspended_until_further_notice = 770,
    Auto_Conference_Cancelled = 771,
    Auto_Conference_Bill_received_pending_payment = 772,
    Auto_Conference_Report_received_from_physician_pending_audit = 773,
    Auto_Conference_Addendum_related_service = 774,
    Auto_Conference_Case_settled_closed_without_service = 775,
    Auto_Conference_Draft_report_forwarded_to_defense_counsel = 776,
    Auto_Conference_IME_expert_consultation_related_service = 777,
    Auto_Conference_IME_re_exam_related_service = 778,
    Auto_Conference_IME_related_service = 779,
    Auto_Conference_Rescheduled_pending_receipt_of_records = 780,
    Auto_Conference_Awaiting_report_pending_payment = 781,
    Auto_Conference_Requested_pending_physician_selection = 782,
    Auto_IME_Conference_Call_Record_Review_Related_Service = 791,
    Auto_Re_Exam_Suspended_until_further_notice = 792,
    Auto_Re_Exam_Pending_scheduling = 793,
    Auto_Re_Exam_Scheduled_pending_receipt_of_records = 794,
    Auto_Re_Exam_Scheduled_pending_compilation_of_records = 795,
    Auto_Re_Exam_Scheduled_pending_confirmation_of_appointment = 796,
    Auto_Re_Exam_Pending_rescheduling = 797,
    Auto_Re_Exam_Awaiting_report = 798,
    Auto_Re_Exam_Awaiting_report_revisions = 799,
    Auto_Re_Exam_Complete_to_be_invoiced = 800,
    Auto_Re_Exam_Complete = 801,
    Auto_Re_Exam_Closed_without_service = 802,
    Auto_Re_Exam_Service_cancelled_by_defense_counsel = 803,
    Auto_Re_Exam_Scheduled_pending_confirmation_of_attendance = 804,
    Auto_Re_Exam_Waiting_for_invoice_from_facility_doctor = 805,
    Auto_Re_Exam_Bill_paid_pending_check_clearance = 806,
    Auto_Re_Exam_Bill_received_pending_payment = 807,
    Auto_Re_Exam_Bill_processed_pending_payment = 808,
    Auto_Re_Exam_Report_received_from_physician_pending_audit = 809,
    Auto_Re_Exam_No_show_manual = 810,
    Auto_Re_Exam_Pending_prepayment = 811,
    Auto_Re_Exam_Pending_recruitment = 812,
    Auto_Re_Exam_Cancelled = 813,
    Auto_Re_Exam_Scheduled_send_letter_notice = 814,
    Auto_Re_Exam_Pending_confirmation_of_notice_received = 815,
    Auto_Re_Exam_Scheduled_pending_confirmation_of_medical_records_received = 816,
    Auto_Re_Exam_Pending_additional_records_report_will_be_held_pending_receipt = 817,
    Auto_Re_Exam_Rescheduled_send_notification_of_appointment = 818,
    Auto_Re_Exam_Rescheduled_pending_confirmation_of_notification_received = 819,
    Auto_Re_Exam_Rescheduled_pending_confirmation_of_appointment = 820,
    Auto_Re_Exam_Rescheduled_pending_confirmation_of_attendance = 821,
    Auto_Re_Exam_Check_cleared_awaiting_report = 822,
    Auto_Re_Exam_Addendum_related_service = 823,
    Auto_Re_Exam_Case_settled_closed_without_service = 824,
    Auto_Re_Exam_Draft_report_forwarded_to_defense_counsel = 825,
    Auto_Re_Exam_IME_expert_consultation_related_service = 826,
    Auto_Re_Exam_IME_re_exam_related_service = 827,
    Auto_Re_Exam_IME_related_service = 828,
    Auto_Re_Exam_Rescheduled_pending_receipt_of_records = 829,
    Auto_Re_Exam_Awaiting_report_pending_payment = 830,
    Auto_Re_Exam_Requested_pending_physician = 831,
    Auto_NF_10_Suspended_until_further_notice = 832,
    Auto_NF_10_Pending_scheduling = 833,
    Auto_NF_10_Scheduled_pending_receipt_of_records = 834,
    Auto_NF_10_Scheduled_pending_compilation_of_records = 835,
    Auto_NF_10_Scheduled_pending_confirmation_of_appointment = 836,
    Auto_NF_10_Pending_rescheduling = 837,
    Auto_NF_10_Awaiting_report = 838,
    Auto_NF_10_Awaiting_report_revisions = 839,
    Auto_NF_10_Complete_to_be_invoiced = 840,
    Auto_NF_10_Complete = 841,
    Auto_NF_10_Closed_without_service = 842,
    Auto_NF_10_Service_cancelled_by_defense_counsel = 843,
    Auto_NF_10_Scheduled_pending_confirmation_of_attendance = 844,
    Auto_NF_10_Waiting_for_invoice_from_facility_doctor = 845,
    Auto_NF_10_Bill_paid_pending_check_clearance = 846,
    Auto_NF_10_Bill_received_pending_payment = 847,
    Auto_NF_10_Bill_processed_pending_payment = 848,
    Auto_NF_10_Report_received_from_physician_pending_audit = 849,
    Auto_NF_10_No_show_manual = 850,
    Auto_NF_10_Pending_prepayment = 851,
    Auto_NF_10_Pending_recruitment = 852,
    Auto_NF_10_Cancelled = 853,
    Auto_NF_10_Scheduled_send_letter_notice = 854,
    Auto_NF_10_Pending_confirmation_of_notice_received = 855,
    Auto_NF_10_Scheduled_pending_confirmation_of_medical_records_received = 856,
    Auto_NF_10_Pending_additional_records_report_will_be_held_pending_receipt = 857,
    Auto_NF_10_Rescheduled_send_notification_of_appointment = 858,
    Auto_NF_10_Rescheduled_pending_confirmation_of_notification_received = 859,
    Auto_NF_10_Rescheduled_pending_confirmation_of_appointment = 860,
    Auto_NF_10_Rescheduled_pending_confirmation_of_attendance = 861,
    Auto_NF_10_Check_cleared_awaiting_report = 862,
    Auto_NF_10_Addendum_related_service = 863,
    Auto_NF_10_Case_settled_closed_without_service = 864,
    Auto_NF_10_Draft_report_forwarded_to_defense_counsel = 865,
    Auto_NF_10_IME_expert_consultation_related_service = 866,
    Auto_NF_10_IME_re_exam_related_service = 867,
    Auto_NF_10_IME_related_service = 868,
    Auto_NF_10_Rescheduled_pending_receipt_of_records = 869,
    Auto_NF_10_Awaiting_report_pending_payment = 870,
    Auto_NF_10_Requested_pending_physician_selection = 871,
    Auto_NF_10_Record_review_related_service = 872,
    Record_Retrievals_HIPAA_Processing_Films_received_pending_distribution_to_service = 1622,
    Subpoena_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax = 1650,
    RadiologyReceivedFromClient_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax = 1900,
    RadiologyReceivedFromClient_Pending_Bill_From_Facility = 1901,
    RadiologyReceivedFromClient_Bill_Received_Pending_Payment = 1902,
    RadiologyReceivedFromClient_Bill_Paid_Pending_Check_Clearance = 1903,
    RadiologyReceivedFromClient_Facility_Requires_Subpoena_Pending_Receipt_From_DC = 1904,
    RadiologyReceivedFromClient_Complete_To_Be_Invoiced = 1905,
    RadiologyReceivedFromClient_Complete = 1906,
    RadiologyReceivedFromClient_Closed_Without_Service = 1907,
    RadiologyReceivedFromClient_Service_Cancelled_By_Defense_Counsel = 1908,
    RadiologyReceivedFromClient_Duplicate_Closed = 1909,
    RadiologyReceivedFromClient_Medical_Records_Received_Pending_Submission_To_Client = 1910,
    RadiologyReceivedFromClient_Suspended_Until_Further_Notice = 1911,
    RadiologyReceivedFromClient_Waiting_For_Payment_Authorization = 1912,
    RadiologyReceivedFromClient_Waiting_For_Medical_Records_Films = 1913,
    RadiologyReceivedFromClient_Cancelled1 = 1914,
    RadiologyReceivedFromClient_Waiting_For_Revised_AZ = 1915,
    RadiologyReceivedFromClient_Case_Settled_Closed_Without_Service = 1916,
    RadiologyReceivedFromClient_Partially_Complete_Pending_Additional_Records = 1917,
    RadiologyReceivedFromClient_Partially_Complete_Pending_Radiology_Discs = 1918,
    RadiologyReceivedFromClient_Pending_Revised_Documentation_From_Client = 1919,
    RadiologyReceivedFromClient_Request_Rejected_By_Facility_Closed_Without_Service = 1920,
    RadiologyReceivedFromClient_Service_Complete_Report_Not_Requested = 1921,
    RadiologyReceivedFromClient_Films_Received_Pending_Distribution_To_Service = 1922,
    RadiologyReceivedFromClient_Opened_for_incoming_radiology = 2172,
    Independent_Radiology_Review_Pending_Receipt_Of_Films_From_Client = 1924,
    WC_Independent_Radiology_Review_Pending_receipt_of_films_from_client = 1993,
    Auto_Independent_Radiology_Pending_receipt_of_films_from_client = 2061,
}

export enum MasterServiceStatuses {
    Awaiting_report = 1,
    Awaiting_report_revisions = 2,
    Bill_paid_pending_check_clearance = 3,
    Bill_processed_pending_payment = 4,
    Bill_received_pending_payment = 5,
    Cancelled = 6,
    Charges_paid_pending_check_clearance = 7,
    Check_cleared = 8,
    Check_cleared_awaiting_report = 9,
    Check_cleared_pending_report = 10,
    Closed_without_service = 11,
    Complete = 12,
    Complete_testimony_cancelled = 13,
    Complete_testimony_completed = 14,
    Complete_to_be_invoiced = 15,
    Conference_call_confirmed = 16,
    Dates_confirmed_pending_payment = 17,
    Dates_requested_pending_confirmation = 18,
    Report_received_from_physician_pending_audit = 19,
    Duplicate__closed = 20,
    Facility_requires_subpoena_pending_receipt_from_DC = 21,
    Films_forwarded_to_expert_pending_report = 22,
    Medical_records_received_pending_submission_to_client = 23,
    No_show_manual = 24,
    Payment_forwarded_to_be_invoiced = 25,
    Pending_bill_from_facility = 26,
    Pending_confirmation_of_notice_received = 27,
    Pending_prepayment = 28,
    Pending_receipt_of_films_from_client = 29,
    Pending_receipt_of_films_from_facility__facilities = 30,
    Pending_recruitment = 31,
    Pending_rescheduling = 32,
    Pending_retrieval_document_submission_by_mail_or_fax = 33,
    Pending_scheduling = 34,
    Physician_selected_waiting_for_authorization_to_move_forward = 35,
    Prepayment_required = 36,
    Records_compiled_awaiting_report = 37,
    Pending_additional_records_report_will_be_held_pending_receipt = 38,
    Report_prepared_awaiting_review_and_revision = 39,
    Rescheduled_pending_confirmation_of_appointment = 40,
    Rescheduled_pending_confirmation_of_attendance = 41,
    Rescheduled_pending_confirmation_of_notification_received = 42,
    Rescheduled_send_notification_of_appointment = 43,
    Scheduled__send_letter__notice = 44,
    Scheduled_confirmation_forwarded_to_physician_and_defense_counsel = 45,
    Scheduled_pending_compilation_of_records = 46,
    Scheduled_pending_confirmation_of_appointment = 47,
    Scheduled_pending_confirmation_of_attendance = 48,
    Scheduled_pending_confirmation_of_medical_records_received = 49,
    Scheduled_pending_receipt_of_records = 50,
    Scheduled_pending_review_of_records = 51,
    Service_cancelled_by_defense_counsel = 52,
    Suspended_until_further_notice = 53,
    Waiting_for_invoice_from_facility__doctor = 54,
    Waiting_for_medical_records__films = 55,
    Waiting_for_payment_authorization = 56,
    Waiting_for_revised_AZ = 57,
    Check_cleared_awaiting_report_revisions = 58,
    IME_related_radiology_review = 59,
    Pending_additional_records_report_will_be_finalized_and_addendum_requested = 60,
    Report_audit_in_progress = 61,
    Report_revisions_pending_payment = 62,
    Rescheduled_pending_compilation_of_records = 63,
    Rescheduled_pending_confirmation_of_medical_records_received__retained = 64,
    Opened_for_incoming_radiology = 116,
}

export enum FollowUpTypes {
    Record_Retrievals_HIPAA_Processing_Bill_paid_pending_check_clearance = 155,
    Record_Retrievals_HIPAA_Processing_Check_cleared = 156,
    Record_Retrievals_HIPAA_Processing_Request_rejected_no_written_rejection_avaiable = 157,
    Record_Retrievals_HIPAA_Processing_Request_rejected_written_rejection_forwarded_to_client = 158,
    Record_Retrievals_HIPAA_Processing_Communication_with_defense_counsel = 159,
    Record_Retrievals_HIPAA_Processing_Communication_with_physician__facility = 160,
    Record_Retrievals_HIPAA_Processing_Retrieval_documents_processed_and_submitted = 23,
    Record_Retrievals_HIPAA_Processing_Followed_up_with_facility_regarding_records_requested = 24,
    Record_Retrievals_HIPAA_Processing_Bill_received_pending_payment = 25,
    Record_Retrievals_HIPAA_Processing_Bill_paid_by_credit_card_pending_medical_records = 26,
    Record_Retrievals_HIPAA_Processing_Electronic_records_received_pending_distribution_to_defense_counsel = 27,
    Record_Retrievals_HIPAA_Processing_Records_forwarded_to_client = 28,
    Record_Retrievals_HIPAA_Processing_Retrieval_documents_resubmitted = 307,
    Record_Retrievals_HIPAA_Processing_Internal_Communication_Regarding_Progress = 308,
    Record_Retrievals_HIPAA_Processing_Internal_Communication = 321,
    Record_Retrievals_HIPAA_Processing_Confirmed_receipt_of_retrieval_request = 491,
    Record_Retrievals_HIPAA_Processing_Check_still_not_cleared_confirm_address_with_facility = 492,
    Record_Retrievals_HIPAA_Processing_Followed_up_with_defense_counsel_regarding_high_value_authorization_requested = 493,
    Record_Retrievals_HIPAA_Processing_Followed_up_with_defense_counsel_regarding_revised_authorization__subpoena_needed = 494,
    Record_Retrievals_HIPAA_Processing_Page_count_discrepancy_being_investigated = 495,
    Record_Retrievals_HIPAA_Processing_Partially_complete_pending_additional_records_not_included_but_requested = 496,
    Record_Retrievals_HIPAA_Processing_Partially_complete_pending_radiology_images_on_disc = 497,
    Record_Retrievals_HIPAA_Processing_Pending_revised_facility_specific_document_from_client = 498,
    Record_Retrievals_HIPAA_Processing_Possibility_of_duplication_of_service_being_investigated = 499,
    Record_Retrievals_HIPAA_Processing_Records_received_from_client_via_plaintiffs_attorney = 500,
    Record_Retrievals_HIPAA_Processing_Records_received_from_facility_via_client = 501,
    Record_Retrievals_HIPAA_Processing_Request_received_Return_to_Sender__first_attempt = 502,
    Record_Retrievals_HIPAA_Processing_Request_received_Return_to_Sender__second_attempt = 503,
    Record_Retrievals_HIPAA_Processing_Request_received_Return_to_Sender__third_attempt = 504,
    Record_Retrievals_HIPAA_Processing_Internal_inquiry_regarding_status_of_outstanding_retrievals = 505,
    Record_Retrievals_HIPAA_Processing_Case_settled_no_records_received = 506,
    Record_Retrievals_HIPAA_Processing_Case_settled_records_received = 507,
    Record_Retrievals_HIPAA_Processing_Duplicate_request_service_cancelled = 508,
    Record_Retrievals_HIPAA_Processing_Efforts_exhausted_due_to_lack_of_contact = 509,
    Record_Retrievals_HIPAA_Processing_Facility_does_not_work_with_third_parties_client_notified = 510,
    Record_Retrievals_HIPAA_Processing_No_records_available_for_claimant_from_facility__no_written_notice_received = 511,
    Record_Retrievals_HIPAA_Processing_No_records_available_for_claimant_from_facility__notice_received_and_forwarded_to_defense_counsel = 512,
    Record_Retrievals_HIPAA_Processing_Hard_copy_records_received_pending_distribution_to_defense_counsel = 532,
    Record_Retrievals_HIPAA_Processing_Paid_via_ACH = 533,
    Record_Retrievals_HIPAA_Processing_High_value_authorization_requested_pending_response = 305,
    IME_Fee_approved_and_forwarded_to_accounting_ = 534,
    IME_Appointment_cancelled_due_to_lack_of_contact = 454,
    IME_Appointment_cancelled_with_physicians_office = 455,
    IME_Appointment_rescheduled = 456,
    IME_Contacted_physicians_office_to_reschedule_left_message = 457,
    IME_Contacted_physicians_office_to_schedule_left_message = 458,
    IME_Defense_counsel_requested_that_appointment_be_cancelled = 459,
    IME_Car_service_cancelled = 460,
    IME_Car_service_rescheduled = 461,
    IME_Car_service_scheduled = 462,
    IME_Interpreter_Cancelled = 463,
    IME_Interpreter_rescheduled = 464,
    IME_Interpreter_Scheduled = 465,
    IME_Additional_records_compiled_and_forwarded_to_physician = 466,
    IME_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 467,
    IME_Bill_received_pending_payment = 468,
    IME_Payment_processed_by_credit_card = 469,
    IME_Case_settled_no_report_needed__requested = 470,
    IME_Case_settled_report_forwarded = 471,
    IME_Internal_Communication = 322,
    IME_Internal_Communication_Regarding_Progress = 309,
    IME_Called_physicians_office_to_schedule_left_message = 1,
    IME_Receipt_of_notification_of_appointment_confirmed = 4,
    IME_Requested_records_from_defense_counsel = 5,
    IME_Records_received = 7,
    IME_Records_compiled_and_forwarded_to_physician__expert = 8,
    IME_IME_appointment_confirmed_with_plaintiffs_attorneys_office = 9,
    IME_Request_for_rescheduling_from_plaintiffs_attorney = 10,
    IME_Request_for_rescheduling_from_defense_counsel = 11,
    IME_Request_for_rescheduling_from_physician = 12,
    IME_Request_for_rescheduling_from_claimant = 13,
    IME_Attendance_at_examination_confirmed = 14,
    IME_Claimant_failed_to_attend_as_scheduled = 15,
    IME_Contacted_physicians_office_regarding_report = 16,
    IME_Report_received_from_physician_pending_audit = 17,
    IME_Report_forwarded_to_client = 18,
    IME_Communication_with_defense_counsel = 19,
    IME_Communication_with_plaintiffs_counsel = 20,
    IME_Communication_with_physician = 21,
    IME_Service_evaluated_for_further_follow_up = 22,
    IME_Recruitment_active_pending_response_from_physician = 161,
    IME_Recruitment_successful_proceed_with_scheduling = 162,
    IME_CVs_forwarded_to_defense_counsel_for_review = 163,
    IME_Contacted_defense_counsel_regarding_pending_physician_selection = 164,
    IME_Contacted_defense_counsel_regarding_scheduling__rescheduling_of_appointment = 165,
    IME_Defense_counsel_has_authorized_scheduling = 166,
    IME_Defense_counsel_has_requested_an_extended_deferment = 167,
    IME_Physician_requires_payment_prior_to_scheduling_of_appointment = 168,
    IME_Appointment_scheduled = 169,
    IME_Defense_counsel_has_requested_deferment_of_notification_of_appointment = 170,
    IME_Pending_plaintiffs_attorneys_contact_information = 171,
    IME_Notification_of_appointment_emailed = 172,
    IME_Notification_of_appointment_faxed = 173,
    IME_Notification_of_appointment_forwarded_by_mail = 174,
    IME_Left_message_at_plaintiffs_attorneys_office_to_confirm_receipt_of_notification_of_appointment = 175,
    IME_Confirmation_of_receipt_of_notification_could_not_be_obtained_advised_defense_counsel_and_moved_forward_with_processing = 176,
    IME_Defense_counsel_asked_that_examination_move_forward_without_records_records_will_be_forwarded_as_soon_as_they_are_available = 177,
    IME_Medical_records_forwarded_to_physician_again_as_they_were_not_received = 178,
    IME_Medical_records_confirmed_received_by_physicians_office = 179,
    IME_Left_message_with_plaintiffs_attorneys_office_to_confirm_appointment = 180,
    IME_Advised_defense_counsel_that_we_have_left_messages_to_confirm_appointment_but_have_not_received_confirmation = 181,
    IME_Followed_up_with_defense_counsel_regarding_additional_records = 182,
    IME_Pending_additional_records_report_will_be_held_pending_receipt = 183,
    IME_Pending_additional_records_report_will_be_finalized_and_addendum_requested = 184,
    IME_Physican_has_advised_that_report_will_be_ready_on_date_specified = 185,
    IME_Report_audit_in_process_pending_submission_to_physician = 186,
    IME_Audited_report_submitted_to_physician_for_finalization_and_signature = 187,
    IME_Prepayment_processed_pending_check_clearance = 188,
    IME_Payment_processed_pending_check_clearance = 189,
    IME_Check_cleared = 190,
    Radiology_review_Pending_receipt_of_films_from_client = 191,
    Radiology_review_Followed_up_with_defense_counsel_regarding_films = 192,
    Radiology_review_Films_copied_and_forwarded_to_physician = 193,
    Radiology_review_Original_films_forwarded_to_physician = 194,
    Radiology_review_Films_confirmed_received_by_physicians_office = 195,
    Radiology_review_Films_forwarded_to_physician_again_as_they_were_not_received = 196,
    Radiology_review_Physican_has_advised_that_report_will_be_ready_on_date_specified = 197,
    Radiology_review_Communication_with_defense_counsel = 199,
    Radiology_review_Communication_with_physician = 200,
    Radiology_review_Internal_Communication_Regarding_Progress = 310,
    Radiology_review_Internal_Communication = 323,
    Radiology_review_Contacted_physicians_office_regarding_report = 29,
    Radiology_review_Report_received_from_physician_pending_audit = 30,
    Radiology_review_Report_forwarded_to_client = 31,
    Radiology_review_Service_evaluated_for_further_follow_up = 32,
    Radiology_review_Contacted_defense_counsel_regarding_physician_selection = 472,
    Radiology_review_Contacted_physicians_office_to_reschedule_left_message = 473,
    Radiology_review_Contacted_physicians_office_to_schedule_left_message = 474,
    Radiology_review_CVs_forwarded_pending_physician_selection_by_DC = 475,
    Radiology_review_Defense_counsel_has_authorized_scheduling = 476,
    Radiology_review_Defense_counsel_requested_that_service_be_cancelled = 477,
    Radiology_review_Service_rescheduled = 478,
    Radiology_review_Additional_records_compiled_and_forwarded_to_physician = 479,
    Radiology_review_Followed_up_with_defense_counsel_on_Radiology_Review = 480,
    Radiology_review_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 481,
    Radiology_review_Scheduled_pending_receipt_of_films = 482,
    Radiology_review_Bill_received_pending_payment = 483,
    Radiology_review_Check_cleared = 484,
    Radiology_review_Payment_processed_by_credit_card = 485,
    Radiology_review_Payment_processed_pending_check_clearance = 486,
    Radiology_review_Case_settled_no_report_needed__requested = 487,
    Radiology_review_Case_settled_report_forwarded = 488,
    Radiology_review_Service_cancelled_due_to_lack_of_contact = 489,
    Radiology_review_Service_cancelled_with_physicians_office = 490,
    Radiology_review_Fee_approved_and_forwarded_to_accounting_ = 535,
    Record_review_Fee_approved_and_forwarded_to_accounting = 536,
    Record_review_Followed_up_with_defense_counsel = 306,
    Record_review_Contacted_defense_counsel_regarding_physician_selection = 513,
    Record_review_Contacted_physicians_office_to_reschedule_left_message = 514,
    Record_review_Contacted_physicians_office_to_schedule_left_message = 515,
    Record_review_CVs_forwarded_pending_physician_selection_by_DC = 516,
    Record_review_Defense_counsel_has_authorized_scheduling = 517,
    Record_review_Defense_counsel_requested_that_service_be_cancelled = 518,
    Record_review_Interpreter_Cancelled = 519,
    Record_review_Interpreter_rescheduled = 520,
    Record_review_Interpreter_Scheduled = 521,
    Record_review_Additional_records_compiled_and_forwarded_to_physician = 522,
    Record_review_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 523,
    Record_review_Bill_received_pending_payment = 524,
    Record_review_Check_cleared = 525,
    Record_review_Payment_processed_by_credit_card = 526,
    Record_review_Payment_processed_pending_check_clearance = 527,
    Record_review_Case_settled_no_report_needed__requested = 528,
    Record_review_Case_settled_report_forwarded = 529,
    Record_review_Service_cancelled_due_to_lack_of_contact = 530,
    Record_review_Service_cancelled_with_physicians_office = 531,
    Record_review_Records_received = 33,
    Record_review_Records_compiled_and_forwarded_to_physician__expert = 34,
    Record_review_Contacted_physicians_office_regarding_report = 35,
    Record_review_Report_received_from_physician_pending_audit = 36,
    Record_review_Report_forwarded_to_client = 37,
    Record_review_Service_evaluated_for_further_follow_up = 38,
    Record_review_Internal_Communication = 324,
    Record_review_Internal_Communication_Regarding_Progress = 311,
    Record_review_Medical_records_forwarded_to_physician_again_as_they_were_not_received = 201,
    Record_review_Medical_records_confirmed_received_by_physicians_office_by_physicians_office = 202,
    Record_review_Pending_additional_records_report_will_be_finalized_and_addendum_requested = 203,
    Record_review_Pending_additional_records_report_will_be_held_pending_receipt = 204,
    Record_review_Followed_up_with_defense_counsel_regarding_additional_records = 205,
    Record_review_Physican_has_advised_that_report_will_be_ready_on_date_specified = 206,
    Record_review_Report_audit_in_process_pending_submission_to_physician = 207,
    Record_review_Communication_with_defense_counsel = 208,
    Record_review_Communication_with_physician = 209,
    Billing__desk_review_Internal_Communication_Regarding_Progress = 312,
    Billing__desk_review_Internal_Communication = 325,
    Billing__desk_review_Contacted_defense_counsel_regarding_physician_selection = 334,
    Billing__desk_review_Contacted_physicians_office_to_schedule_left_message = 335,
    Billing__desk_review_CVs_forwarded_pending_physician_selection_by_DC = 336,
    Billing__desk_review_Defense_counsel_has_authorized_scheduling = 337,
    Billing__desk_review_Defense_counsel_requested_that_service_be_cancelled = 338,
    Billing__desk_review_Interpreter_Cancelled = 339,
    Billing__desk_review_Interpreter_rescheduled = 340,
    Billing__desk_review_Interpreter_Scheduled = 341,
    Billing__desk_review_Additional_records_compiled_and_forwarded_to_physician = 342,
    Billing__desk_review_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 343,
    Billing__desk_review_Report_received_from_physician_pending_audit = 344,
    Billing__desk_review_Bill_received_pending_payment = 345,
    Billing__desk_review_Check_cleared = 346,
    Billing__desk_review_Payment_processed_by_credit_card = 347,
    Billing__desk_review_Payment_processed_pending_check_clearance = 348,
    Billing__desk_review_Case_settled_no_report_needed__requested = 349,
    Billing__desk_review_Case_settled_report_forwarded = 350,
    Billing__desk_review_Service_cancelled_due_to_lack_of_contact = 351,
    Billing__desk_review_Service_cancelled_with_physicians_office = 352,
    Billing__desk_review_Records_received = 39,
    Billing__desk_review_Record_review_begun = 40,
    Billing__desk_review_Draft_report_completed_pending_review = 41,
    Billing__desk_review_Report_forwarded_to_client = 42,
    Billing__desk_review_Service_evaluated_for_further_follow_up = 43,
    Billing__desk_review_Communication_with_defense_counsel = 154,
    Billing__desk_review_Fee_approved_and_forwarded_to_accounting = 537,
    CRS_Full_Day_Testimony_Possible_dates_received_from_defense_counsel = 44,
    CRS_Full_Day_Testimony_Agreeable_dates_received_from_physician__expert = 45,
    CRS_Full_Day_Testimony_Testimony_date_agreed_upon_by_defense_counsel__expert = 46,
    CRS_Full_Day_Testimony_Testimony_date_confirmed_by_defense_counsel_and_instruction_to_forward_prepayment_explicitly_received = 47,
    CRS_Full_Day_Testimony_Testimony_paid = 48,
    CRS_Full_Day_Testimony_Testimony_completed = 49,
    CRS_Full_Day_Testimony_Testimony_cancelled = 50,
    CRS_Full_Day_Testimony_Internal_Communication = 326,
    CRS_Full_Day_Testimony_Internal_Communication_Regarding_Progress = 313,
    CRS_Full_Day_Testimony_Dates_forwarded_to_physician_for_review_and_selection_pending_response = 210,
    CRS_Full_Day_Testimony_Followed_up_with_physician_regarding_dates_requested = 211,
    CRS_Full_Day_Testimony_Forwarded_alternative_dates_from_physician_to_defense_counsel = 212,
    CRS_Full_Day_Testimony__Service_evaluated_for_further_follow_up = 213,
    CRS_Full_Day_Testimony_Communication_with_defense_counsel = 214,
    CRS_Full_Day_Testimony_Communication_with_physician = 215,
    CRS_Half_Day_Testimony_Dates_forwarded_to_physician_for_review_and_selection_pending_response = 216,
    CRS_Half_Day_Testimony_Followed_up_with_physician_regarding_dates_requested = 217,
    CRS_Half_Day_Testimony_Forwarded_alternative_dates_from_physician_to_defense_counsel = 218,
    CRS_Half_Day_Testimony__Service_evaluated_for_further_follow_up = 219,
    CRS_Half_Day_Testimony_Communication_with_defense_counsel = 220,
    CRS_Half_Day_Testimony_Communication_with_physician = 221,
    CRS_Half_Day_Testimony_Internal_Communication_Regarding_Progress = 314,
    CRS_Half_Day_Testimony_Internal_Communication = 327,
    CRS_Half_Day_Testimony_Possible_dates_received_from_defense_counsel = 51,
    CRS_Half_Day_Testimony_Agreeable_dates_received_from_physician__expert = 52,
    CRS_Half_Day_Testimony_Testimony_date_agreed_upon_by_defense_counsel__expert = 53,
    CRS_Half_Day_Testimony_Testimony_date_confirmed_by_defense_counsel_and_instruction_to_forward_prepayment_explicitly_received = 54,
    CRS_Half_Day_Testimony_Testimony_paid = 55,
    CRS_Half_Day_Testimony_Testimony_completed = 56,
    CRS_Half_Day_Testimony_Testimony_cancelled = 57,
    CRS_Half_Day_Testimony_Bill_received_pending_payment = 374,
    CRS_Half_Day_Testimony_Check_cleared = 375,
    CRS_Half_Day_Testimony_Payment_processed_pending_check_clearance = 376,
    CRS_Deposition_Bill_received_pending_payment = 371,
    CRS_Deposition_Check_cleared = 372,
    CRS_Deposition_Payment_processed_pending_check_clearance = 373,
    CRS_Deposition_Possible_dates_received_from_defense_counsel = 58,
    CRS_Deposition_Agreeable_dates_received_from_physician__expert = 59,
    CRS_Deposition_Testimony_date_agreed_upon_by_defense_counsel__expert = 60,
    CRS_Deposition_Testimony_date_confirmed_by_defense_counsel_and_instruction_to_forward_prepayment_explicitly_received = 61,
    CRS_Deposition_Testimony_paid = 62,
    CRS_Deposition_Testimony_completed = 63,
    CRS_Deposition_Testimony_cancelled = 64,
    CRS_Deposition_Internal_Communication = 328,
    CRS_Deposition_Internal_Communication_Regarding_Progress = 315,
    CRS_Deposition_Dates_forwarded_to_physician_for_review_and_selection_pending_response = 222,
    CRS_Deposition_Followed_up_with_physician_regarding_dates_requested = 223,
    CRS_Deposition_Forwarded_alternative_dates_from_physician_to_defense_counsel = 224,
    CRS_Deposition__Service_evaluated_for_further_follow_up = 225,
    CRS_Deposition_Communication_with_defense_counsel = 226,
    CRS_Deposition_Communication_with_physician = 227,
    CRS_Conference_Call__Followed_up_with_physician_regarding_dates_requested = 228,
    CRS_Conference_Call__Dates_forwarded_to_physician_for_review_and_selection_pending_response = 229,
    CRS_Conference_Call__Forwarded_alternative_dates_from_physician_to_defense_counsel = 230,
    CRS_Conference_Call__Service_evaluated_for_further_follow_up = 231,
    CRS_Conference_Call__Communication_with_defense_counsel = 232,
    CRS_Conference_Call__Communication_with_physician = 233,
    CRS_Conference_Call__Internal_Communication_Regarding_Progress = 316,
    CRS_Conference_Call__Internal_Communication = 329,
    CRS_Conference_Call__Appointment_cancelled_due_to_lack_of_contact = 353,
    CRS_Conference_Call__Appointment_cancelled_with_physicians_office = 354,
    CRS_Conference_Call__Appointment_rescheduled = 355,
    CRS_Conference_Call__Contacted_defense_counsel_regarding_physician_selection = 356,
    CRS_Conference_Call__Contacted_physicians_office_to_reschedule_left_message = 357,
    CRS_Conference_Call__Contacted_physicians_office_to_schedule_left_message = 358,
    CRS_Conference_Call__CVs_forwarded_pending_physician_selection_by_DC = 359,
    CRS_Conference_Call__Defense_counsel_has_authorized_scheduling = 360,
    CRS_Conference_Call__Defense_counsel_requested_that_appointment_be_cancelled = 361,
    CRS_Conference_Call__Additional_records_compiled_and_forwarded_to_physician = 362,
    CRS_Conference_Call__Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 363,
    CRS_Conference_Call__Report_received_from_physician_pending_audit = 364,
    CRS_Conference_Call__Bill_received_pending_payment = 365,
    CRS_Conference_Call__Check_cleared = 366,
    CRS_Conference_Call__Payment_processed_by_credit_card = 367,
    CRS_Conference_Call__Payment_processed_pending_check_clearance = 368,
    CRS_Conference_Call__Case_settled_no_report_needed__requested = 369,
    CRS_Conference_Call__Case_settled_report_forwarded = 370,
    CRS_Conference_Call__Possible_dates_received_from_defense_counsel = 65,
    CRS_Conference_Call__Agreeable_dates_received_from_physician__expert = 66,
    CRS_Conference_Call__Call_date_agreed_upon_by_defense_counsel__expert = 67,
    CRS_Conference_Call__Confirmed_that_call_is_scheduled_with_physicians_office = 68,
    CRS_Conference_Call__Confirmation_that_call_was_completed = 69,
    CRS_Conference_Call__Fee_approved_and_forwarded_to_accounting = 538,
    IME_ReExam_Fee_approved_and_forwarded_to_accounting = 539,
    IME_Re_Exam_Appointment_cancelled_due_to_lack_of_contact = 436,
    IME_Re_Exam_Appointment_cancelled_with_physicians_office = 437,
    IME_Re_Exam_Appointment_rescheduled = 438,
    IME_Re_Exam_Contacted_physicians_office_to_reschedule_left_message = 439,
    IME_Re_Exam_Contacted_physicians_office_to_schedule_left_message = 440,
    IME_Re_Exam_Defense_counsel_requested_that_appointment_be_cancelled = 441,
    IME_Re_Exam_Car_service_cancelled = 442,
    IME_Re_Exam_Car_service_rescheduled = 443,
    IME_Re_Exam_Car_service_scheduled = 444,
    IME_Re_Exam_Interpreter_Cancelled = 445,
    IME_Re_Exam_Interpreter_rescheduled = 446,
    IME_Re_Exam_Interpreter_Scheduled = 447,
    IME_Re_Exam_Additional_records_compiled_and_forwarded_to_physician = 448,
    IME_Re_Exam_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 449,
    IME_Re_Exam_Bill_received_pending_payment = 450,
    IME_Re_Exam_Payment_processed_by_credit_card = 451,
    IME_Re_Exam_Case_settled_no_report_needed__requested = 452,
    IME_Re_Exam_Case_settled_report_forwarded = 453,
    IME_Re_Exam_Called_physicians_office_to_schedule_left_message = 70,
    IME_Re_Exam_Receipt_of_notification_of_appointment_confirmed = 73,
    IME_Re_Exam_Requested_records_from_defense_counsel = 74,
    IME_Re_Exam_Records_received = 75,
    IME_Re_Exam_Records_compiled_and_forwarded_to_physician__expert = 76,
    IME_Re_Exam_IME_appointment_confirmed_with_plaintiffs_attorneys_office = 77,
    IME_Re_Exam_Request_for_rescheduling_from_plaintiffs_attorney = 78,
    IME_Re_Exam_Request_for_rescheduling_from_defense_counsel = 79,
    IME_Re_Exam_Request_for_rescheduling_from_physician = 80,
    IME_Re_Exam_Request_for_rescheduling_from_claimant = 81,
    IME_Re_Exam_Attendance_at_examination_confirmed = 82,
    IME_Re_Exam_Claimant_failed_to_attend_as_scheduled = 83,
    IME_Re_Exam_Contacted_physicians_office_regarding_report = 84,
    IME_Re_Exam_Report_received_from_physician_pending_audit = 85,
    IME_Re_Exam_Report_forwarded_to_client = 86,
    IME_Re_Exam_Communication_with_defense_counsel = 87,
    IME_Re_Exam_Communication_with_plaintiffs_counsel = 88,
    IME_Re_Exam_Communication_with_physician = 89,
    IME_Re_Exam_Service_evaluated_for_further_follow_up = 90,
    IME_Re_Exam_Internal_Communication = 330,
    IME_Re_Exam_Internal_Communication_Regarding_Progress = 317,
    IME_Re_Exam_Recruitment_active_pending_response_from_physician = 234,
    IME_Re_Exam_Recruitment_successful_proceed_with_scheduling = 235,
    IME_Re_Exam_CVs_forwarded_to_defense_counsel_for_review = 236,
    IME_Re_Exam_Contacted_defense_counsel_regarding_pending_physician_selection = 237,
    IME_Re_Exam_Contacted_defense_counsel_regarding_scheduling__rescheduling_of_appointment = 238,
    IME_Re_Exam_Defense_counsel_has_authorized_scheduling = 239,
    IME_Re_Exam_Defense_counsel_has_requested_an_extended_deferment = 240,
    IME_Re_Exam_Physician_requires_payment_prior_to_scheduling_of_appointment = 241,
    IME_Re_Exam_Appointment_scheduled = 242,
    IME_Re_Exam_Defense_counsel_has_requested_deferment_of_notification_of_appointment = 243,
    IME_Re_Exam_Pending_plaintiffs_attorneys_contact_information = 244,
    IME_Re_Exam_Notification_of_appointment_emailed = 245,
    IME_Re_Exam_Notification_of_appointment_faxed = 246,
    IME_Re_Exam_Notification_of_appointment_forwarded_by_mail = 247,
    IME_Re_Exam_Left_message_at_plaintiffs_attorneys_office_to_confirm_receipt_of_notification_of_appointment = 248,
    IME_Re_Exam_Confirmation_of_receipt_of_notification_could_not_be_obtained_advised_defense_counsel_and_moved_forward_with_processing = 249,
    IME_Re_Exam_Defense_counsel_asked_that_examination_move_forward_without_records_records_will_be_forwarded_as_soon_as_they_are_available = 250,
    IME_Re_Exam_Medical_records_forwarded_to_physician_again_as_they_were_not_received = 251,
    IME_Re_Exam_Medical_records_confirmed_received_by_physicians_office = 252,
    IME_Re_Exam_Left_message_with_plaintiffs_attorneys_office_to_confirm_appointment = 253,
    IME_Re_Exam_Advised_defense_counsel_that_we_have_left_messages_to_confirm_appointment_but_have_not_received_confirmation = 254,
    IME_Re_Exam_Followed_up_with_defense_counsel_regarding_additional_records = 255,
    IME_Re_Exam_Pending_additional_records_report_will_be_held_pending_receipt = 256,
    IME_Re_Exam_Pending_additional_records_report_will_be_finalized_and_addendum_requested = 257,
    IME_Re_Exam_Physican_has_advised_that_report_will_be_ready_on_date_specified = 258,
    IME_Re_Exam_Report_audit_in_process_pending_submission_to_physician = 259,
    IME_Re_Exam_Audited_report_submitted_to_physician_for_finalization_and_signature = 260,
    IME_Re_Exam_Prepayment_processed_pending_check_clearance = 261,
    IME_Re_Exam_Payment_processed_pending_check_clearance = 262,
    IME_Re_Exam_Check_cleared = 263,
    IME_Addendum_Medical_records_forwarded_to_physician_again_as_they_were_not_received = 264,
    IME_Addendum_Medical_records_confirmed_received_by_physicians_office = 265,
    IME_Addendum_Pending_additional_records_report_will_be_finalized_and_addendum_requested = 266,
    IME_Addendum_Pending_additional_records_report_will_be_held_pending_receipt = 267,
    IME_Addendum_Followed_up_with_defense_counsel_regarding_additional_records = 268,
    IME_Addendum_Physican_has_advised_that_report_will_be_ready_on_date_specified = 269,
    IME_Addendum_Report_audit_in_process_pending_submission_to_physician = 270,
    IME_Addendum_Audited_report_submitted_to_physician_for_finalization_and_signature = 271,
    IME_Addendum_Internal_Communication_Regarding_Progress = 318,
    IME_Addendum_Internal_Communication = 331,
    IME_Addendum_Requested_records_from_defense_counsel = 95,
    IME_Addendum_Records_received = 96,
    IME_Addendum_Records_compiled_and_forwarded_to_physician__expert = 97,
    IME_Addendum_Contacted_physicians_office_regarding_report = 105,
    IME_Addendum_Report_received_from_physician_pending_audit = 106,
    IME_Addendum_Report_forwarded_to_client = 107,
    IME_Addendum_Communication_with_defense_counsel = 108,
    IME_Addendum_Communication_with_physician = 110,
    IME_Addendum_Service_evaluated_for_further_follow_up = 111,
    IME_Addendum_Contacted_defense_counsel_regarding_physician_selection = 377,
    IME_Addendum_Contacted_physicians_office_to_reschedule_left_message = 378,
    IME_Addendum_Contacted_physicians_office_to_schedule_left_message = 379,
    IME_Addendum_CVs_forwarded_pending_physician_selection_by_DC = 380,
    IME_Addendum_Defense_counsel_has_authorized_scheduling = 381,
    IME_Addendum_Defense_counsel_requested_that_service_be_cancelled = 382,
    IME_Addendum_Service_rescheduled = 383,
    IME_Addendum_Interpreter_Cancelled = 384,
    IME_Addendum_Interpreter_rescheduled = 385,
    IME_Addendum_Interpreter_Scheduled = 386,
    IME_Addendum_Additional_records_compiled_and_forwarded_to_physician = 387,
    IME_Addendum_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 388,
    IME_Addendum_Bill_received_pending_payment = 389,
    IME_Addendum_Check_cleared = 390,
    IME_Addendum_Payment_processed_by_credit_card = 391,
    IME_Addendum_Payment_processed_pending_check_clearance = 392,
    IME_Addendum_Case_settled_no_report_needed__requested = 393,
    IME_Addendum_Case_settled_report_forwarded = 394,
    IME_Addendum_Service_cancelled_due_to_lack_of_contact = 395,
    IME_Addendum_Service_cancelled_with_physicians_office = 396,
    IME_Addendum_Fee_approved_and_forwarded_to_accounting = 540,
    IME_Expert_Consultation_Fee_approved_and_forwarded_to_accounting_ = 541,
    IME_Expert_Consultation_Appointment_cancelled_due_to_lack_of_contact = 418,
    IME_Expert_Consultation_Appointment_cancelled_with_physicians_office = 419,
    IME_Expert_Consultation_Appointment_rescheduled = 420,
    IME_Expert_Consultation_Contacted_physicians_office_to_reschedule_left_message = 421,
    IME_Expert_Consultation_Contacted_physicians_office_to_schedule_left_message = 422,
    IME_Expert_Consultation_Defense_counsel_requested_that_appointment_be_cancelled = 423,
    IME_Expert_Consultation_Car_service_cancelled = 424,
    IME_Expert_Consultation_Car_service_rescheduled = 425,
    IME_Expert_Consultation_Car_service_scheduled = 426,
    IME_Expert_Consultation_Interpreter_Cancelled = 427,
    IME_Expert_Consultation_Interpreter_rescheduled = 428,
    IME_Expert_Consultation_Interpreter_Scheduled = 429,
    IME_Expert_Consultation_Additional_records_compiled_and_forwarded_to_physician = 430,
    IME_Expert_Consultation_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 431,
    IME_Expert_Consultation_Bill_received_pending_payment = 432,
    IME_Expert_Consultation_Payment_processed_by_credit_card = 433,
    IME_Expert_Consultation_Case_settled_no_report_needed__requested = 434,
    IME_Expert_Consultation_Case_settled_report_forwarded = 435,
    IME_Expert_Consultation_Called_physicians_office_to_schedule_left_message = 112,
    IME_Expert_Consultation_Receipt_of_notification_of_appointment_confirmed = 115,
    IME_Expert_Consultation_Requested_records_from_defense_counsel = 116,
    IME_Expert_Consultation_Records_received = 117,
    IME_Expert_Consultation_Records_compiled_and_forwarded_to_physician__expert = 118,
    IME_Expert_Consultation_IME_appointment_confirmed_with_plaintiffs_attorneys_office = 119,
    IME_Expert_Consultation_Request_for_rescheduling_from_plaintiffs_attorney = 120,
    IME_Expert_Consultation_Request_for_rescheduling_from_defense_counsel = 121,
    IME_Expert_Consultation_Request_for_rescheduling_from_physician = 122,
    IME_Expert_Consultation_Request_for_rescheduling_from_claimant = 123,
    IME_Expert_Consultation_Attendance_at_examination_confirmed = 124,
    IME_Expert_Consultation_Claimant_failed_to_attend_as_scheduled = 125,
    IME_Expert_Consultation_Contacted_physicians_office_regarding_report = 126,
    IME_Expert_Consultation_Report_received_from_physician_pending_audit = 127,
    IME_Expert_Consultation_Report_forwarded_to_client = 128,
    IME_Expert_Consultation_Communication_with_defense_counsel = 129,
    IME_Expert_Consultation_Communication_with_plaintiffs_counsel = 130,
    IME_Expert_Consultation_Communication_with_physician = 131,
    IME_Expert_Consultation_Service_evaluated_for_further_follow_up = 132,
    IME_Expert_Consultation_Internal_Communication = 332,
    IME_Expert_Consultation_Internal_Communication_Regarding_Progress = 319,
    IME_Expert_Consultation_Recruitment_active_pending_response_from_physician = 274,
    IME_Expert_Consultation_Recruitment_successful_proceed_with_scheduling = 275,
    IME_Expert_Consultation_CVs_forwarded_to_defense_counsel_for_review = 276,
    IME_Expert_Consultation_Contacted_defense_counsel_regarding_pending_physician_selection = 277,
    IME_Expert_Consultation_Contacted_defense_counsel_regarding_scheduling__rescheduling_of_appointment = 278,
    IME_Expert_Consultation_Defense_counsel_has_authorized_scheduling = 279,
    IME_Expert_Consultation_Defense_counsel_has_requested_an_extended_deferment = 280,
    IME_Expert_Consultation_Physician_requires_payment_prior_to_scheduling_of_appointment = 281,
    IME_Expert_Consultation_Appointment_scheduled = 282,
    IME_Expert_Consultation_Defense_counsel_has_requested_deferment_of_notification_of_appointment = 283,
    IME_Expert_Consultation_Pending_plaintiffs_attorneys_contact_information = 284,
    IME_Expert_Consultation_Notification_of_appointment_emailed = 285,
    IME_Expert_Consultation_Notification_of_appointment_faxed = 286,
    IME_Expert_Consultation_Notification_of_appointment_forwarded_by_mail = 287,
    IME_Expert_Consultation_Left_message_at_plaintiffs_attorneys_office_to_confirm_receipt_of_notification_of_appointment = 288,
    IME_Expert_Consultation_Confirmation_of_receipt_of_notification_could_not_be_obtained_advised_defense_counsel_and_moved_forward_with_processing = 289,
    IME_Expert_Consultation_Defense_counsel_asked_that_examination_move_forward_without_records_records_will_be_forwarded_as_soon_as_they_are_available = 290,
    IME_Expert_Consultation_Medical_records_forwarded_to_physician_again_as_they_were_not_received = 291,
    IME_Expert_Consultation_Medical_records_confirmed_received_by_physicians_office = 292,
    IME_Expert_Consultation_Left_message_with_plaintiffs_attorneys_office_to_confirm_appointment = 293,
    IME_Expert_Consultation_Advised_defense_counsel_that_we_have_left_messages_to_confirm_appointment_but_have_not_received_confirmation = 294,
    IME_Expert_Consultation_Followed_up_with_defense_counsel_regarding_additional_records = 295,
    IME_Expert_Consultation_Pending_additional_records_report_will_be_held_pending_receipt = 296,
    IME_Expert_Consultation_Pending_additional_records_report_will_be_finalized_and_addendum_requested = 297,
    IME_Expert_Consultation_Physican_has_advised_that_report_will_be_ready_on_date_specified = 298,
    IME_Expert_Consultation_Report_audit_in_process_pending_submission_to_physician = 299,
    IME_Expert_Consultation_Audited_report_submitted_to_physician_for_finalization_and_signature = 300,
    IME_Expert_Consultation_Prepayment_processed_pending_check_clearance = 301,
    IME_Expert_Consultation_Payment_processed_pending_check_clearance = 302,
    IME_Expert_Consultation_Check_cleared = 303,
    IME_Conference_Call_Confirmed_that_call_is_scheduled_with_physicians_office = 304,
    IME_Conference_Call_Internal_Communication_Regarding_Progress = 320,
    IME_Conference_Call_Internal_Communication = 333,
    IME_Conference_Call_Records_received = 138,
    IME_Conference_Call_Records_compiled_and_forwarded_to_physician__expert = 139,
    IME_Conference_Call_Confirmation_that_call_was_completed = 147,
    IME_Conference_Call_Communication_with_defense_counsel = 150,
    IME_Conference_Call_Communication_with_physician = 152,
    IME_Conference_Call_Service_evaluated_for_further_follow_up = 153,
    IME_Conference_Call_Contacted_defense_counsel_regarding_physician_selection = 397,
    IME_Conference_Call_Contacted_physicians_office_to_reschedule_left_message = 398,
    IME_Conference_Call_Contacted_physicians_office_to_schedule_left_message = 399,
    IME_Conference_Call_CVs_forwarded_pending_physician_selection_by_DC = 400,
    IME_Conference_Call_Defense_counsel_has_authorized_scheduling = 401,
    IME_Conference_Call_Defense_counsel_requested_that_appointment_be_cancelled = 402,
    IME_Conference_Call_Service_rescheduled = 403,
    IME_Conference_Call_Interpreter_Cancelled = 404,
    IME_Conference_Call_Interpreter_rescheduled = 405,
    IME_Conference_Call_Interpreter_Scheduled = 406,
    IME_Conference_Call_Additional_records_compiled_and_forwarded_to_physician = 407,
    IME_Conference_Call_Retrieval_services_still_outstanding_pending_completion_for_additional_records_to_be_forwarded_to_physician_for_addendum = 408,
    IME_Conference_Call_Report_received_from_physician_pending_audit = 409,
    IME_Conference_Call_Bill_received_pending_payment = 410,
    IME_Conference_Call_Check_cleared = 411,
    IME_Conference_Call_Payment_processed_by_credit_card = 412,
    IME_Conference_Call_Payment_processed_pending_check_clearance = 413,
    IME_Conference_Call_Case_settled_no_report_needed__requested = 414,
    IME_Conference_Call_Case_settled_report_forwarded = 415,
    IME_Conference_Call_Service_cancelled_due_to_lack_of_contact = 416,
    IME_Conference_Call_Service_cancelled_with_physicians_office = 417,
    IME_Conference_Call_Fee_approved_and_forwarded_to_accounting = 542,
    Record_Retrievals_HIPAA_Processing_Report_Due_By = 1607,
    Independent_Medical_Examination_Report_Due_By = 1608,
    Radiology_review_Report_Due_By = 1609,
    Record_review_Report_Due_By = 1610,
    Billing_desk_review_Report_Due_By = 1611,
    Full_Day_Testimony_Report_Due_By = 1612,
    Half_Day_Testimony_Report_Due_By = 1613,
    Deposition_Report_Due_By = 1614,
    Conference_Call_between_attorney_physician_or_expert_Report_Due_By = 1615,
    Re_Examination_Report_Due_By = 1616,
    Addendum_Additional_Records_Report_Due_By = 1617,
    Expert_Consultation_Report_Due_By = 1618,
    Conference_Call_Report_Due_By = 1619,
    WC_Independent_Medical_Examination_Report_Due_By = 1620,
    No_Fault_PIP_Independent_Medical_Examination_Report_Due_By = 1621,
    WC_Record_Review_Report_Due_By = 1622,
    No_Fault_PIP_Record_Review_Report_Due_By = 1623,
    WC_Re_Examination_Report_Due_By = 1624,
    WC_Radiology_Review_Report_Due_By = 1625,
    WC_Functional_Capacity_Report_Due_By = 1626,
    No_Fault_PIP_Peer_Review_Report_Due_By = 1627,
    WC_Addendum_Additional_Records_Report_Due_By = 1628,
    WC_Conference_Call_Report_Due_By = 1629,
    No_Fault_PIP_Radiology_Review_Report_Due_By = 1630,
    No_Fault_PIP_Addendum_Additional_Records_Report_Due_By = 1631,
    No_Fault_PIP_Conference_Call_Report_Due_By = 1632,
    No_Fault_PIP_Re_Examination_Report_Due_By = 1633,
    No_Fault_PIP_NF10_Report_Due_By = 1634,
    Record_Retrievals_HIPAA_Invoice_forwarded_to_client = 1635,
    IME_Contacted_physicians_office_to_confirm_receipt_of_records = 1639,
    Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1649,
    Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1657,
    IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records = 1680,
    IME_Expert_Consultation_Contacted_physicians_office_to_confirm_receipt_of_records = 1694,
    WC_IME_Contacted_physicians_office_to_confirm_receipt_of_records = 1708,
    Auto_IME_Contacted_physicians_office_to_confirm_receipt_of_records = 1713,
    WC_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1718,
    Auto_Record_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1721,
    WC_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records = 1724,
    Auto_IME_Re_Exam_Contacted_physicians_office_to_confirm_receipt_of_records = 1751,
    WC_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1728,
    Auto_Radiology_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1742,
    Auto_Peer_Review_Contacted_physicians_office_to_confirm_receipt_of_records = 1733,
    WC_IME_Appointment_scheduled = 649,
    Auto_IME_Appointment_scheduled = 807,
    WC_IME_Re_Exam_Appointment_scheduled = 982,
    Auto_IME_Re_Exam_Appointment_scheduled = 1456,
    WC_IME_Appointment_rescheduled = 647,
    Auto_IME_Appointment_rescheduled = 805,
    WC_IME_Re_Exam_Appointment_rescheduled = 1008,
    Auto_IME_Re_Exam_Appointment_rescheduled = 1455,
    RadiologyReceivedFromClient_Retrieval_Documents_Processed_And_Submitted = 6000,
    RadiologyReceivedFromClient_Followed_Up_With_Facility_Regarding_Records_Requested = 6001,
    RadiologyReceivedFromClient_Bill_Received_Pending_Payment = 6002,
    RadiologyReceivedFromClient_Bill_Paid_By_Credit_Card_Pending_Medical_Records = 6003,
    RadiologyReceivedFromClient_Electronic_Records_Received_Pending_Distribution_To_Defense_Counsel = 6004,
    RadiologyReceivedFromClient_Records_Forwarded_To_Client = 6005,
    RadiologyReceivedFromClient_Bill_Paid_Pending_Check_Clearance = 6006,
    RadiologyReceivedFromClient_Check_Cleared = 6007,
    RadiologyReceivedFromClient_Request_Rejected_No_Written_Rejection_Available = 6008,
    RadiologyReceivedFromClient_Request_Rejected_Written_Rejection_Forwarded_To_Client = 6009,
    RadiologyReceivedFromClient_Communication_With_Defense_Counsel = 6010,
    RadiologyReceivedFromClient_Communication_With_Physician_Facility = 6011,
    RadiologyReceivedFromClient_High_Value_Authorization_Requested_Pending_Response = 6012,
    RadiologyReceivedFromClient_Retrieval_Documents_Resubmitted = 6013,
    RadiologyReceivedFromClient_Internal_Communication_Regarding_Progress = 6014,
    RadiologyReceivedFromClient_Internal_Communication = 6015,
    RadiologyReceivedFromClient_Confirmed_Receipt_Of_Retrieval_Request = 6016,
    RadiologyReceivedFromClient_Check_Still_Not_Cleared_Confirm_Address_With_Facility = 6017,
    RadiologyReceivedFromClient_Followed_Up_With_Defense_Counsel_Regarding_High_Value_Authorization_Requested = 6018,
    RadiologyReceivedFromClient_Followed_Up_With_Defense_Counsel_Regarding_Revised_Authorization_Subpoena_Needed = 6019,
    RadiologyReceivedFromClient_Page_Count_Discrepancy_Being_Investigated = 6020,
    RadiologyReceivedFromClient_Partially_Complete_Pending_Additional_Records_Not_Included_But_Requested = 6021,
    RadiologyReceivedFromClient_Partially_Complete_Pending_Radiology_Images_On_Disc = 6022,
    RadiologyReceivedFromClient_Pending_Revised_Facility_Specific_Document_From_Client = 6023,
    RadiologyReceivedFromClient_Possibility_Of_Duplication_Of_Service_Being_Investigated = 6024,
    RadiologyReceivedFromClient_Records_Received_From_Client_Via_Plaintiffs_Attorney = 6025,
    RadiologyReceivedFromClient_Records_Received_From_Facility_Via_Client = 6026,
    RadiologyReceivedFromClient_Request_Received_Return_To_Sender_First_Attempt = 6027,
    RadiologyReceivedFromClient_Request_Received_Return_To_Sender_Second_Attempt = 6028,
    RadiologyReceivedFromClient_Request_Received_Return_To_Sender_Third_Attempt = 6029,
    RadiologyReceivedFromClient_Internal_Inquiry_Regarding_Status_Of_Outstanding_Retrievals = 6030,
    RadiologyReceivedFromClient_Case_Settled_No_Records_Received = 6031,
    RadiologyReceivedFromClient_Case_Settled_Records_Received = 6032,
    RadiologyReceivedFromClient_Duplicate_Request_Service_Cancelled = 6033,
    RadiologyReceivedFromClient_Efforts_Exhausted_Due_To_Lack_Of_Contact = 6034,
    RadiologyReceivedFromClient_Facility_Does_Not_Work_With_Third_Parties_Client_Notified = 6035,
    RadiologyReceivedFromClient_No_Records_Available_For_Claimant_From_Facility_No_Written_Notice_Received = 6036,
    RadiologyReceivedFromClient_No_Records_Available_For_Claimant_From_Facility_Notice_Received_And_Forwarded_To_Defense_Counsel = 6037,
    RadiologyReceivedFromClient_Hard_Copy_Records_Received_Pending_Distribution_To_Defense_Counsel = 6038,
    RadiologyReceivedFromClient_Paid_Via_ACH = 6039,
    RadiologyReceivedFromClient_Defense_Counsel_Has_Changed = 6040,
    RadiologyReceivedFromClient_Billing_Should_Be_Split_Amongst_Listed_Co_Counsels = 6041,
    RadiologyReceivedFromClient_Radiology_Retrieval_Submitted_Electronically = 6042,
    RadiologyReceivedFromClient_Report_Due_By = 6043,
    RadiologyReceivedFromClient_Invoice_Forwarded_To_Client = 6044,
    RadiologyReceivedFromClient_Expert_Holding_Report_For_Balance_Due_Payment = 6045,
    RadiologyReceivedFromClient_Followed_Up_With_Client_For_Claim_File_Number = 6046,
    RadiologyReceivedFromClient_Preliminary_Invoice_Delayed_Until_Prepayment_Submitted_Month_Of_Exam = 6047,
    RadiologyReceivedFromClient_Accounting_Followed_Up_With_Facility_Regarding_Check_Receipt_And_Records = 6048,
    RadiologyReceivedFromClient_Radiology_Received_From_Client = 6049,
    RadiologyReceivedFromClient_Bill_To_Party_Has_Changed = 6050,
    RadiologyReceivedFromClient_Radiology_Disc_Received_Pending_Distribution_To_Client = 6051,
    RadiologyReceivedFromClient_Followed_Up_With_Facility_Regarding_Payment_Receipt = 6052,
    RadiologyReceivedFromClient_Client_Has_Provided_A_Budget_Within_Which_All_Work_Must_Fall = 6053,
    RadiologyReceivedFromClient_Request_Processed_Via_Subpoena = 6054,
    RadiologyReceivedFromClient_High_Value_Authorization_Approval_Received = 6055,
    RadiologyReceivedFromClient_Payment_Processed = 6056,
    RadiologyReceivedFromClient_Trial_Authorization_Processed_And_Submitted = 6057,
    RadiologyReceivedFromClient_Report_Requested_By_Defense_Counsel = 6058,
    RadiologyReceivedFromClient_Client_Requested_Fee_Estimate = 6059,
    RadiologyReceivedFromClient_Plaintiff_Lodged_Objection_To_Subpoena = 6060,
    Independent_Radiology_Review_Report_Due_By = 6105,
}

export enum IMedClaimStatusIdEnums {
    Active = 1,
    Close = 2,
    Archived = 3,
    Settled = 4,
    Pending = 5,
}

export enum ServiceIdEnums {
    Record_Retrievals_HIPAA_Processing = 1,
    IME = 2,
    Film_Review = 3,
    Record_Review = 4,
    Billing_Desk_Review = 5,
    Full_Day_Testimony = 6,
    Half_Day_Testimony = 7,
    Deposition = 8,
    Conference_Call_Between_Attorney_Physician_Or_Expert = 9,
    IME_Re_Examination = 10,
    IME_Addendum_Additional_Records = 11,
    IME_Expert_Consultation = 12,
    IME_Conference_Call = 13,
    IME_WC = 14,
    IME_Auto = 15,
    WC_Record_Review = 16,
    AUTO_Record_Review = 17,
    WC_IME_Re_Examination = 18,
    WC_Film_Review = 19,
    WC_Functional_Capacity = 20,
    AUTO_Peer_Review = 21,
    WC_Addendum = 22,
    WC_Conference_Call = 23,
    Auto_Radiology_Review = 24,
    Auto_Addendum = 25,
    Auto_Conference_Call = 26,
    Auto_Re_Exam = 27,
    Auto_NF_10 = 28,
    Subpoena_Processing = 29,
    Radiology_Received_From_Client = 30,
    Independent_Film_Review = 31,
    WC_Independent_Film_Review = 32,
    Auto_Independent_Film_Review = 33,
}

export enum AddressBookSelectionEnums {
    Adjuster = 'Adjuster',
    ParaLegal = 'ParaLegal',
    DefenseAttorney = 'DefenseAttorney',
    Other = 'Other',
    PlantiffAttorney = 'PlantiffAttorney',
    CarrierParty = 'CarrierParty',
    ProcessServer = 'ProcessServer',
    Physician = 'Physician',
    AddressBook = 'AddressBook',
    Individual = 'Individual',
    TransportationServicesVendor = 'TransportationServicesVendor',
    TranslationServicesVendor = 'TranslationServicesVendor',
    BackgroundInvestigationVendor = 'BackgroundInvestigationVendor',
    SurveillanceVendor = 'SurveillanceVendor',
    ClaimantsAttorney = 'ClaimantsAttorney',
    CarriersCounsel = 'CarriersCounsel',
    Employer = 'Employer',
    Attorney = 'Attorney',
    NoConstraintSearch = '',
    TranscriptionServicesVendor = 'TranscriptionServicesVendor',
    RentalFacilityVendor = 'RentalFacilityVendor',
}

export enum TaskStatusEnums {
    Pending = 1,
    Completed = 2,
    CompletedBySystem = 3,
}

export enum Settings {
    ReminderEmail = 5,
    Signature = 6,
}

export enum ServiceType {
    Service = 1,
    Followups = 2,
    Status = 3,
}

export enum SubServiceEnums {
    Radiology_Retrieval = 1,
    Additional_Records = 2,
    Multiple_Insurance_Claims = 3,
    Hard_Copy = 4,
    UPS_Shipping = 5,
    Certified_Or_Return_Receipt = 6,
    Subpoena_Radiology_Retrieval = 7,
    Subpoena_Additional_Records = 8,
    Subpoena_Multiple_Insurance_Claims = 9,
    Subpoena_Hard_Copy = 10,
    Subpoena_UPS_Shipping = 11,
    Subpoena_Certified_Or_Return_Receipt = 12,
}

export enum DocumentQueueAssignmentTypes {
    Claims = 1,
    Services = 2,
    Physicians = 3,
    User = 4,
}

export enum AddressBookDocument {
    Intro_Letter = 1,
    Welcome_Letter = 2,
    Appointment_Notification_Letter = 3,
    Intro_New_To_IMEs_Letter = 4,
}

export enum AppointmentDocument {
    Appointment_Notification_Letter = 1,
    Appointment_Cancel_Letter = 2,
    Appointment_Missed_Letter = 3,
    Appointment_Reschedule_Letter = 4,
    Appointment_Confirmation_Letter = 5,
    Covid_Appointment_Reschedule_TBD_Letter = 6,
    Covid_Appointment_Reschedule_Letter = 7,
    Appointment_Reschedule_TBD_Letter = 8,
    Appointment_TBD_Letter = 9,
}

export enum ConflictOfInterestFormSelection {
    notSelected = 0,
    selected,
}

export enum AttachmentServiceTypeEnums {
    Case = 1,
    Address_Book = 2,
    User = 3,
    IME = 4,
    IME_Re_Exam = 5,
    IME_Addendum = 6,
    IME_Expert_Consultation = 7,
    IME_Conference_Call = 8,
    Record_Retrieval = 9,
    Record_Review = 10,
    Radiology_Review = 11,
    Billing_Desk_Review = 12,
    Testimony_Deposition = 13,
    Conference_Call_Between_Attorney = 14,
    Client_Imed_Claim = 15,
    Auto_IME = 16,
    WC_IME_Re_Exam = 18,
    WC_Radiology_Review = 19,
    WC_Functional_Capacity = 20,
    WC_Addendum = 22,
    WC_Conference_Call = 23,
    Subpoena_Service = 24,
    Independent_Film_Review = 31,
    WC_Independent_Film_Review = 32,
    Auto_Independent_Film_Review = 33,
}

export enum FaxEmailEnum {
    FAX = 1,
    EMAIL = 2,
    DOWNLOAD = 3,
}

export enum TrendingServiceChartEnums {
    Record_Retrieval_HIPPA_Processing = 1,
    Radiology_Review = 3,
    Record_Review = 4,
    Billing_Desk_Review = 5,
    IME = 6,
    Court_Related_Service = 7,
}

export enum DashboardTypeEnums {
    Management = 1,
    Operational = 2,
    Both = 3,
    NoWidgets = 4,
}

export enum StatusValidationMessageTypeEnums {
    Soft_Pop_Up,
    Hard_Pop_Up,
    Authorization_Doc_Hard_Pop_Up,
    Authorization_Doc_Soft_Pop_Up,
    Legal_Foundation_Hard_Pop_Up,
    Legal_Foundation_Soft_Pop_Up,
    IME_Email_Confirmation,
    Record_Review_Email_Confirmation,
    Bill_Desk_Review_Email_Confirmation,
}

export enum ValidationOverrideEnums {
    LegalFoundation,
    AuthorizationDocument,
    EmailConfirmation,
}

export enum PopUpTypeEnums {
    Hard_Pop_Up,
    Soft_Pop_Up,
}

export enum ViewLogEntityTypeEnums {
    Case,
    Service,
    AddressBook,
}

export enum AuditLogRedirectTypeEnums {
    IMED_CLAIM_SERVICE = 1,
    PHONE_NUMBER,
    VIEW_LOG,
    DOCUMENT,
    DOCUMENT_QUEUE,
    BILL_DESK_REVIEW,
    IME_SERVICE,
    IME_APPOINTMENT,
    ADDRESS,
    EMAIL_ADDRESS,
    PHYSICIAN_SPECIALTY,
    PHYSICIAN_SPECIALTY_DETAILS,
    RADIOLOGY_REVIEW_SERVICE,
    RECORD_REVIEW_SERVICE,
    FILM_INFORMATION,
    RECORD_RETRIEVAL_SERVICE,
    IMED_CLAIM_SUBSERVICE,
    CONFERENCE_CALL_SERVICE,
    TESTIMONY_DEPOSITION_SERVICE,
    CRS_CONFERENCE_CALL_DATE_TIME,
    CRS_TESTIMONY_DEPOSITION_DATE,
    PHYSICIAN,
    INDIVIDUAL,
    ADDRESS_BOOK,
    TASK_TEMPLATE,
    FOLLOWUP,
}

export enum DocumentEntityTypeEnums {
    CASE = 1,
    SERVICE,
    ADDRESS_BOOK,
    INDIVIDUAL,
    PHYSICIAN,
}

export enum ViaEnums {
    EMAIL,
    MAIL,
    UPS,
    SYSTEM_LINK,
}

export enum CommunicationMethodEnums {
    TELEPHONE,
    EMAIL,
    MAIL,
    ELECTRONIC,
    NA,
}

export enum MergeFieldTypeEnums {
    TASK = 1,
    EMAIL_ADDRESS,
    EMAIL_SUBJECT,
    EMAIL_BODY,
    EMAIL_BOTH,
    DYNAMIC_TEMPLATE = 14,
    DYNAMIC_TEMPLATE_SERVICE = 15,
    DYNAMIC_TEMPLATE_CASE = 16,
    DYNAMIC_TEMPLATE_INDIVIDUAL = 17,
    DYNAMIC_TEMPLATE_ADDRESSBOOK = 18,
    ADDRESS_BOOK_EMAIL_ADDRESS = 19,
    ADDRESS_BOOK_EMAIL_SUBJECT = 20,
    ADDRESS_BOOK_EMAIL_BODY = 21,
    ADDRESS_BOOK_EMAIL_BOTH = 22,
    CASE_EMAIL_ADDRESS = 23,
    CASE_EMAIL_SUBJECT = 24,
    CASE_EMAIL_BODY = 25,
    CASE_EMAIL_BOTH = 26,
}

export enum SystemGeneratedEmailTemplateEnums {
    ADDITIONAL_SERVICES = 1,
    BACKGROUND_INVESTIGATION,
    SURVEILLANCE,
    TRANSLATION_SERVICES,
    SCHEDULED_PENDING_RECEIPT_OF_RECORDS,
    FEE_SCHEDULE,
    IME_PENDING_SCHEDULING,
    Radiology_Review_Fee_Schedule,
    Record_Review_Fee_Schedule,
}

export enum EmailTemplateTypes {
    SERVICE = 1,
    TASK,
    FEE_SCHEDULE,
    ADDITIONAL_SERVICES,
    STATUS,
    ADDRESS_BOOK,
    CASE = 7,
}

export enum TaskTemplateTypes {
    SERVICE = 1,
    CASE = 2,
}

export enum CommunicationLogTypeEnums {
    IMED_CLAIM_SERVICE = 1,
    INDIVIDUAL,
    TASK,
    FOLLOW_UP,
    CASE,
}

export enum DocumentTransactionTypeEnums {
    MANUAL_UPLOAD_API = 1,
    MANUAL_DOWNLOAD_API,
    DOCUMENT_QUEUE_DOWNLOAD_API,
    MANUAL_UPLOAD_FE,
    MANUAL_DOWNLOAD_FE,
    DOCUMENT_QUEUE_DOWNLOAD_FE,
}

export enum OtherServiceTypeEnums {
    Examination = 1,
    Retrievals = 2,
    CRS = 3,
    TranslationServices = 4,
}

export enum DocumentUploadSources {
    Manual = 1,
    Email = 2,
    Fax = 3,
    Document_Queue = 4,
    Archival = 5,
    Download = 6,
    Client_Portal_Upload = 7,
    Claim_Request_Forms = 8,
    System = 10,
}

export enum RequestorTypeEnums {
    Defense_Attorney = 1,
    Paralegal = 2,
    NA = 3,
    Carrier = 4,
    Employer = 5,
    Claimant = 6,
}

export enum PhoneTypes {
    Office = 1,
    Doctor = 2,
    Alternate = 3,
    Office_fax = 4,
    Home_cell_emergency = 5,
}

export enum EmailTypes {
    Office = 1,
    Report = 2,
    IME_Billing = 3,
    Retrieval_Billing = 4,
}

export enum ClaimTypeEnums {
    GL = 1,
    WC = 2,
    Auto = 3,
}

export enum ClaimTypeNames {
    GL = 'General Liability',
    WC = 'Workers Compensation',
    Auto = 'No Fault / PIP',
}

export enum AutomatedTemplateFaxEmailEntityTypeEnums {
    ImedClaim = 1,
    ImedService = 2,
    AddressBookFacility = 3,
    AddressBookIndividual = 4,
}

export enum FaxEmailEntityTypeEnums {
    IntroLetter = 1,
    WelcomeLetter = 2,
    IntroNewToIMEsLetter = 3,
    RadiologyCoverLetter = 4,
    IMEReExam = 5,
    IMEAddendum = 6,
    AppointmentTBDLetter = 7,
    RecordsRetrievalSimple = 8,
    RecordsRejectionLetter = 9,
    RecordsRetrievalComplex = 10,
    FilmRetrievalLabel = 11,
    NoRecordsOnFile = 12,
    UndeliverableRecords = 13,
    AdditionalRecordsRetrieved = 14,
    RecordReview = 15,
    AppointmentNotificationLetter = 16,
    AppointmentCancelLetter = 17,
    AppointmentMissedLetter = 18,
    AppointmentRescheduleLetter = 19,
    AppointmentConfirmationLetter = 20,
    AppointmentRescheduleTBDLetter = 21,
}

export enum AdditionalServiceTypeEnums {
    RadiologyReview = 1,
    Addendum = 2,
    ConferenceCall = 3,
    NF10 = 4,
}

export enum AddressBookAssociationTypeEnums {
    SYSTEM_TASK = 1,
    EMAIL_ASSOCIATIONS = 2,
}

export enum ClosedServiceStatusEnums {
    Cancelled = 6,
    CaseSettledClosedWithoutService = 67,
    Closedwithoutservice = 11,
    Complete = 12,
    CompleteTestimonyCancelled = 13,
    CompleteTestimonyCompleted = 14,
    CompleteToBeInvoiced = 15,
    CompleteToBeInvoicedReportNotRequested = 80,
    DuplicateClosed = 20,
    RequestRejectedByFacilityClosedWithoutService = 75,
    ServicecCanceledByCarrierAdjuster = 86,
    ServiceCanceledByDefenseCounsel = 52,
    ServiceCompleteReportNotRequested = 87,
}

export enum MasterFollowUpTypes {
    Check_Cleared = 18,
    IME_appointment_confirmed_with_plaintiff_attorney_office = 57,
    Prepayment_processed_pending_check_clearance = 80,
    Receipt_of_notification_of_appointment_confirmed = 81,
    Records_received = 85,
    Appointment_scheduled = 8,
    Appointment_rescheduled = 121,
    Claimant_failed_to_attend_as_scheduled = 20,
    Report_forwarded_to_client = 92,
    Bill_paid_pending_check_clearance = 13,
    Payment_processed_pending_check_clearance = 71,
    Testimony_paid = 114,
    Followed_up_with_facility_regarding_records_requested = 50,
    Retrieval_documents_processed_and_submitted = 105,
    Retrieval_documents_resubmitted = 115,
    Contacted_physician_office_to_confirm_receipt_of_records = 334,
    Testimony_cancelled = 110,
    Pending_additional_records_report_will_be_finalized_and_addendum_requested = 72,
    Pending_additional_records_report_will_be_held_pending_receipt = 73,
    Defense_counsel_has_requested_an_extended_deferment = 37,
    Defense_counsel_has_requested_deferment_of_notification_of_appointment = 38,
    Physician_has_advised_that_report_will_be_ready_on_date_specified = 76,
    Films_confirmed_received_by_physician_office = 41,
    Films_forwarded_to_physician_again_as_they_were_not_received = 43,
    Medical_records_confirmed_received_by_physician_office = 63,
    Medical_records_forwarded_to_physician_again_as_they_were_not_received = 64,
    Request_for_rescheduling_from_claimant = 96,
    Request_for_rescheduling_from_defense_counsel = 97,
    Request_for_rescheduling_from_physician = 98,
    Request_for_rescheduling_from_plaintiff_attorney = 99,
    Films_copied_and_forwarded_to_physician = 42,
    Original_films_forwarded_to_physician = 69,
    Records_forwarded_to_client = 84,
    Records_compiled_and_forwarded_to_physicia_expert = 83,
    Fee_approved_and_forwarded_to_accounting = 163,
    Report_due_by_DATE = 332,
    Attendance_at_examination_confirmed = 9,
    Confirmation_that_call_was_completed = 26,
    Service_evaluated_for_further_follow_up = 109,
    Invoice_forwarded_to_client = 333,
    Billing_should_be_split_amongst_listed_Co_counsels = 167,
    Defense_counsel_has_changed = 166,
}

export enum TaskTemplateDueDateTypes {
    Appointment = 1,
    Report = 2,
    Compilation = 3,
}

export enum TimeFrames {
    After = 'After',
    Before = 'Before',
    On = 'On',
}