import { DynamicField, DynamicLabel, LabelPositions } from '@mt-ng2/dynamic-form';
import { IDocument } from '../interfaces/document';
import { DocumentDynamicControls, IDocumentDynamicControlsParameters } from '../form-controls/document.form-controls';

export class DocumentDynamicControlsPartial extends DocumentDynamicControls {
    constructor(document?: IDocument, additionalParameters?: IDocumentDynamicControlsParameters) {
        super(document, additionalParameters);

        (<DynamicField>this.Form.NoOfPages).setRequired(false);
        (<DynamicField>this.Form.NoOfPages).labelPosition.position = LabelPositions.Hidden;
    }
}
