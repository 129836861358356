export enum AttachmentServiceTypeEnums {
    Facility_Portal= 25,
}

export enum DocumentUploadSources {
    Manual = 1,
}

export enum DocumentTransactionTypeEnums {
    MANUAL_UPLOAD_API = 1,
    MANUAL_UPLOAD_FE,
}
